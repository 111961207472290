/* eslint-disable @typescript-eslint/comma-dangle */
/* eslint-disable @typescript-eslint/quotes */
import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Input,
  DatePicker,
  Select,
  Checkbox,
  Button,
  ConfigProvider,
  FormProps,
  message,
} from "antd";
import ReactFlagsSelect from "react-flags-select";

import { DataContext } from "../../context/DataContext";
import { useNavigate } from "react-router-dom";
import dict from "../../dict";

const { Option } = Select;

export interface FieldType {
  name: string;
  lastname: string;
  email: string;
  dni: string;
  arrival: string;
  country: string;
  //promo: boolean;
  terms: boolean;
  status: string;
  lang: string;
}

export interface IOneTrustData {
  identifier: string;
  CL_Name: string;
  CL_Surnames: string;
  CL_PASAPORTE: string; 
}

export const RegistrationForm: React.FC = () => {
  const { 
    sendFormRegister, 
    sendDataToOneTrust,
    setFormDraft,
    dataState 
  } = useContext(DataContext);

  const { language, formDraft } = dataState;
  const home = dict.home[language as keyof typeof dict.home];

  const [form] = Form.useForm();
  const [focusedField, setFocusedField] = useState<string | null>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    form.setFieldsValue(formDraft);
  }, [formDraft, form]);

  const checkFormValidity = () => {
    try {
      const fieldsValues = form.getFieldsValue([
        "name",
        "lastname",
        "email",
        "dni",
        "arrival",
        "country",
        "gender",
        "terms",
      ]);

      const isFormFilled = Object.values(fieldsValues).every((value) => value);
      setIsButtonDisabled(!isFormFilled);
    } catch (error) {
      console.error("Error al verificar la validez del formulario:", error);
      message.error("Error al verificar la validez del formulario");
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleValuesChange = (_changed: any, allValues: any) => {
    checkFormValidity();
    setFormDraft(allValues);
  };

  const handleFocus = (field: string) => {
    setFocusedField(field);
  };

  const handleBlur = (field: string) => {
    if (!form.getFieldValue(field)) {
      setFocusedField(null);
    }
  };

  const handleChangeGenre = (value: string) => {
    handleValuesChange(null, { gender: value });
    checkFormValidity();
  };

  const onFinish: (values: FieldType) => Promise<void> = async (values) => {
    const sendClient = await sendFormRegister({
      ...values,
      status: "created",
      lang: language,
    });
    if (!sendClient.ok) {
      message.error(`Error al enviar el formulario: ${sendClient.error}`);
    } else {
      const sendOneTrust = await sendDataToOneTrust({
        identifier: values.email,
        CL_Name: values.name,
        CL_Surnames: values.lastname,
        CL_PASAPORTE: values.dni,
      });
      console.info(sendOneTrust.ok ? "onetrust" : "...");
      message.success("Formulario enviado correctamente");
      form.resetFields();
      navigate(`/${language}/success`, { state: { email: values.email } });
    }
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (
    errorInfo
  ) => {
    message.error(
      `Error al enviar el formulario: ${
        errorInfo.errorFields?.[0]?.errors?.[0] || JSON.stringify(errorInfo)
      }`
    );
  };

  useEffect(() => {
    checkFormValidity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ConfigProvider
      theme={{
        components: {
          DatePicker: {
            colorBgContainer: "transparent",
            colorBorderSecondary: "transparent",
            colorBgSolid: "gray",
            borderRadius: 8,
            hoverBorderColor: "#8A91A4",
            colorPrimary: "#240070",
            colorText: "#000",
            colorTextDisabled: "#D9D9D9",
            colorPrimaryHover: "#240070",
          },
          Select: {
            colorBgContainer: "transparent",
            colorBorder: "#8A91A4",
            borderRadius: 8,
            colorPrimary: "#240070",
            colorText: "#000",
            colorTextDisabled: "#D9D9D9",
            colorPrimaryHover: "#240070",
          },
          Button: {
            colorBorder: "#8A91A4",
          },
          Tabs: {
            fontSize: 20,
          },
        },
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="custom-form"
        onValuesChange={handleValuesChange}
      >
        <div className="form-row">
          <Form.Item
            name="name"
            className={`custom-form-item ${
              focusedField === "name" ? "focused" : ""
            }`}
            rules={[
              { required: true, message: home.errorName },
              { max: 40, message: home.errorName2 },
            ]}
          >
            <div className="input-wrapper">
              <Input
                placeholder=" "
                onFocus={() => handleFocus("name")}
                onBlur={() => handleBlur("name")}
                maxLength={40}
                value={formDraft.name}
              />
              <label className="floating-label">{home.name}</label>
            </div>
          </Form.Item>

          <Form.Item
            name="lastname"
            className={`custom-form-item ${
              focusedField === "lastname" ? "focused" : ""
            }`}
            rules={[
              { required: true, message: home.errorLastName },
              { max: 40, message: home.errorLastName2 },
            ]}
          >
            <div className="input-wrapper">
              <Input
                placeholder=" "
                onFocus={() => handleFocus("lastname")}
                onBlur={() => handleBlur("lastname")}
                maxLength={40}
                value={formDraft.lastname}
              />
              <label className="floating-label">{home.lastName}</label>
            </div>
          </Form.Item>
        </div>

        <Form.Item
          name="email"
          className={`custom-form-item ${
            focusedField === "email" ? "focused" : ""
          }`}
          rules={[
            { required: true, message: home.errorEmail },
            { type: "email", message: home.errorEmail2 },
            { max: 100, message: home.errorEmail3 },
          ]}
        >
          <div className="input-wrapper">
            <Input
              placeholder=" "
              onFocus={() => handleFocus("email")}
              onBlur={() => handleBlur("email")}
              maxLength={100}
              value={formDraft.email}
            />
            <label className="floating-label">{home.email}</label>
          </div>
        </Form.Item>

        <div className="form-row">
          <Form.Item
            name="dni"
            className={`custom-form-item ${
              focusedField === "dni" ? "focused" : ""
            }`}
            rules={[
              { required: true, message: home.errorDocument },
              { max: 30, message: home.errorDocument2 },
            ]}
          >
            <div className="input-wrapper">
              <Input
                placeholder=" "
                onFocus={() => handleFocus("dni")}
                onBlur={() => handleBlur("dni")}
                maxLength={30}
                value={formDraft.dni}
              />
              <label className="floating-label">{home.document}</label>
            </div>
          </Form.Item>

          <Form.Item
            name="arrival"
            className={`custom-form-item ${
              focusedField === "arrival" || formDraft.arrival
                ? "focused"
                : ""
            }`}
            rules={[{ required: true, message: home.errorArrival }]}
          >
            <div className="input-wrapper">
              <DatePicker
                style={{ width: "100%" }}
                placeholder=" "
                onFocus={() => handleFocus("arrival")}
                onBlur={() => handleBlur("arrival")}
                onChange={(date) => {
                  handleValuesChange(null, { arrival: date });
                }}
                popupClassName="custom-datepicker-dropdown"
                value={formDraft.arrival}
                //getPopupContainer={(trigger) => trigger?.parentElement || document.body }
              />
              <label className="floating-label">{home.arrival}</label>
            </div>
          </Form.Item>
        </div>

        <div className="form-row">
          <Form.Item
            name="country"
            className={`item-flag custom-form-item ${
              focusedField === "country" || formDraft.country ? "focused" : ""
            }`}
            rules={[{ required: true, message: home.errorCountry }]}
          >
            <div className="input-wrapper">
              <button
                type="button"
                onFocus={() => handleFocus("country")}
                onBlur={() => handleBlur("country")}
                className="home-custom-flag-select-container"
                style={{
                  background: "none",
                  border: "none",
                  padding: 0,
                  width: "100%",
                }}
              >
                <ReactFlagsSelect
                  className={`home-custom-flag-select ${
                    form.getFieldValue('country') ? "country-selected" : ""
                  }`}
                  selected={formDraft.country ? formDraft.country : ""}
                  onSelect={(code) => {
                    form.setFieldsValue({ country: code });
                    handleValuesChange(null, { country: code });
                  }}
                  placeholder={form.getFieldValue('country') ? form.getFieldValue('country') : " "}
                  searchable={true}
                  searchPlaceholder={home.searchCountry}
                  selectButtonClassName="home-custom-flag-select-button"
                  countries={["CL"]}
                  blacklistCountries
                />
              </button>
              <label className="floating-label">{home.country}</label>
            </div>
          </Form.Item>

          <Form.Item
            name="gender"
            className={`custom-form-item ${
              focusedField === "gender" || formDraft.gender ? "focused" : ""
            }`}
            rules={[{ required: true, message: home.errorGenre }]}
          >
            <div className="input-wrapper">
              <Select
                //value={selectGenre}
                value={formDraft.gender}
                placeholder=" "
                onFocus={() => handleFocus("gender")}
                onBlur={() => handleBlur("gender")}
                className={`home-custom-select ${
                  form.getFieldValue('gender') ? "gender-select" : ""
                }`}
                onChange={handleChangeGenre}
              >
                <Option value="masculino">{home.male}</Option>
                <Option value="femenino">{home.female}</Option>
                <Option value="otro">{home.other}</Option>
              </Select>
              <label className="floating-label">{home.genre}</label>
            </div>
          </Form.Item>
        </div>

        {/* <Form.Item
          name="promo"
          valuePropName="checked"
          className="custom-checkbox"
          initialValue={false}
        >
          <Checkbox>{home.promo}</Checkbox>
        </Form.Item> */}

        <Form.Item
          name="terms"
          valuePropName="checked"
          className="custom-checkbox"
          rules={[{ required: true, message: home.errorTerms }]}
          initialValue={false}
        >
          <Checkbox>
            {home.terms + " "}
            <button
              type="button"
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                background: "none",
                border: "none",
                padding: 0,
                color: "inherit",
                font: "inherit",
              }}
              onClick={() => {
                navigate(`/${language}/tyc`, { state: { tab: "tyc" } });
              }}
            >
              {home.termsTyC}
            </button>
            {" " + home.termsAnd + " "}
            <button
              type="button"
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                background: "none",
                border: "none",
                padding: 0,
                color: "inherit",
                font: "inherit",
              }}
              onClick={() => {
                navigate(`/${language}/tyc`, { state: { tab: "politica" } });
              }}
            >
              {home.termsPol}
            </button>
          </Checkbox>
        </Form.Item>

        <Form.Item>
          <div className="home-button-container">
            <Button
              type="primary"
              htmlType="submit"
              className={`submit-button ${
                isButtonDisabled ? "button-disabled" : "button-active"
              }`}
              disabled={isButtonDisabled} // Botón deshabilitado si no están todos los campos completos
            >
              {home.submit}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </ConfigProvider>
  );
};
