const accesibilidad = {
  es: {
    grown: 'Aumentar Tamaño',
    decrease: 'Disminuir Tamaño',
    contrast: 'Cambiar Contraste',
    accesibility: '¿Qué es accesibilidad?',
    download: 'Descargar',
    title: '¿Qué es accesibilidad?',
    body: `<p>La accesibilidad busca que todas las personas que son usuarias de un sitio web puedan navegar e interactuar, sin importar si es que tienen o no algún grado de discapacidad. En Cencosud nos hemos preocupado de que nuestros términos y condiciones cumplan con los requisitos mínimos recomendados por el Servicio Nacional de la Discapacidad (Senadis) basados en los estándares entregados por el consorcio de la World Wide Web en sus Pautas de Accesibilidad para el Contenido Web (WCAG) 2.0.</p>
        <strong>Tamaño de letras</strong>
        <p>La fuente de esta página tiene dimensiones relativas, así las personas podrán controlar los tamaños con los controles habilitados.</p>
        <strong>Contraste</strong>
        <p>Habilitamos una opción con mayor contraste para mejorar la legibilidad de los textos en las personas que puedan necesitarlo</p>
        <strong>Lectores de pantalla</strong>
        <p>Nos preocupamos de habilitar el uso de los lectores de pantalla e integramos elementos de navegación del teclado para que las personas puedan utilizarlo mediante un lector de pantalla. Para poder utilizarlo, debes bajar una extensión o instalar un software de Screen Reader. Algunos ejemplos gratuitos que puedes utilizar son:</p>
        <ul>
        <li>NVDA (para Windows)</li>
        <li>VoiceOver (para Mac)</li>
        <li>Screen reader (extensión de Google Chrome)</li>
        </ul>
        <p>En celulares, solo debes ir a la configuración y activar la opción de VoiceOver en dispositivos iOS o TalkBack en Android.</p>`,
    close: 'Cerrar',
  },
  en: {
    grown: 'Increase Font',
    decrease: 'Decrease Font',
    contrast: 'Change Contrast',
    accesibility: 'What is accessibility?',
    download: 'Download',
    title: 'What is accessibility?',
    body: `<p>Accessibility seeks that all people who are users of a website can navigate and interact, regardless of whether or not they have any degree of disability. At Cencosud we have made sure that our terms and conditions meet the minimum requirements recommended by the National Disability Service (Senadis) based on the standards provided by the World Wide Web consortium in its Web Content Accessibility Guidelines (WCAG) 2.0.</p>
        <strong>Font size</strong>
        <p>The font on this page has relative dimensions, so people can control the sizes with the enabled controls.</p>
        <strong>Contrast</strong>
        <p>We have enabled an option with higher contrast to improve the readability of texts in people who may need it</p>
        <strong>Screen readers</strong>
        <p>We worry about enabling the use of screen readers and integrating keyboard navigation elements so that people can use it through a screen reader. To use it, you must download an extension or install Screen Reader software. Some free examples you can use are:</p>
        <ul>
        <li>NVDA (for Windows)</li>
        <li>VoiceOver (for Mac)</li>
        <li>Screen reader (Google Chrome extension)</li>
        </ul>
        <p>On cell phones, you just have to go to the settings and activate the VoiceOver option on iOS devices or TalkBack on Android.</p>`,
    close: 'Close',
  },
  pt: {
    grown: 'Aumentar Fonte',
    decrease: 'Diminuir Fonte',
    contrast: 'Alterar Contraste',
    accesibility: 'O que é acessibilidade?',
    download: 'Baixar',
    title: 'O que é acessibilidade?',
    body: `<p>A acessibilidade busca que todas as pessoas que são usuárias de um site possam navegar e interagir, independentemente de terem ou não algum grau de deficiência. Na Cencosud, nos certificamos de que nossos termos e condições atendam aos requisitos mínimos recomendados pelo Serviço Nacional de Deficiência (Senadis) com base nos padrões fornecidos pelo consórcio World Wide Web em suas Diretrizes de Acessibilidade ao Conteúdo da Web (WCAG) 2.0.</p>
        <strong>Tamanho da fonte</strong>
        <p>A fonte desta página tem dimensões relativas, para que as pessoas possam controlar os tamanhos com os controles habilitados.</p>
        <strong>Contraste</strong>
        <p>Habilitamos uma opção com maior contraste para melhorar a legibilidade dos textos em pessoas que possam precisar</p>
        <strong>Leitores de tela</strong>
        <p>Nos preocupamos em habilitar o uso de leitores de tela e integrar elementos de navegação do teclado para que as pessoas possam usá-lo por meio de um leitor de tela. Para usá-lo, você deve baixar uma extensão ou instalar um software de leitor de tela. Alguns exemplos gratuitos que você pode usar são:</p>
        <ul>
        <li>NVDA (para Windows)</li>
        <li>VoiceOver (para Mac)</li>
        <li>Leitor de tela (extensão do Google Chrome)</li>
        </ul>
        <p>Em celulares, basta ir às configurações e ativar a opção VoiceOver em dispositivos iOS ou TalkBack no Android.</p>`,
    close: 'Fechar',
  },
};

export default accesibilidad;