/* eslint-disable @typescript-eslint/comma-dangle */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ConfigProvider, Layout, Menu, Select } from "antd";
import { GlobalOutlined } from "@ant-design/icons";
import Logo from "../../assets/img/MarcaCenco.svg";
import "./header.css";
import { DataContext } from "../../context/DataContext";

const { Header } = Layout;
const { Option } = Select;

interface HeaderProps {
  isVisible: boolean;
}

const CustomHeader: React.FC<HeaderProps> = ({ isVisible }) => {
  const { setLanguage, dataState } = useContext(DataContext);
  const { language } = dataState;
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    language ? language : "es"
  );

  // Función para manejar el cambio de selección
  const handleChange = (newLang: string) => {
    setLanguage(newLang);

    const pathSegments = location.pathname.split("/");

    const knownLangs = ["es", "en", "pt"];

    if (knownLangs.includes(pathSegments[1])) {
      pathSegments[1] = newLang;
      console.log("newPathSegments", pathSegments);
      navigate(pathSegments.join("/"));
    } else {
      navigate(`/${newLang}${location.pathname}`);
    }
  };

  useEffect(() => {
    language && setSelectedLanguage(language);
  }, [language]);

  // Función para mostrar la abreviatura correspondiente al valor seleccionado
  const renderSelectedValue = () => {
    switch (selectedLanguage) {
      case "es":
        return "ES";
      case "en":
        return "EN";
      case "pt":
        return "PT";
      default:
        return "ES";
    }
  };

  const menuItems = [
    {
      className: "custom_header_nav_item",
      key: "1",
      icon: <GlobalOutlined style={{ fontSize: "20px" }} />, // Aumentamos el tamaño del ícono
      label: (
        <Select
          value={renderSelectedValue()} // Mostrar la abreviatura seleccionada
          style={{ width: 70 }}
          popupClassName="custom_select_dropdown"
          onDropdownVisibleChange={() => setOpen(!open)}
          suffixIcon={
            <span className={`custom-arrow ${open ? "rotate" : ""}`}>
              &#9660;
            </span>
          }
          dropdownStyle={{
            width: "150px",
            zIndex: 10000,
          }}
          dropdownAlign={{
            points: ["tc", "bc"], // Alineación superior del dropdown con la parte inferior del Select
            offset: [-20, 8], // Desplazamiento vertical para evitar solapamiento
          }}
          onChange={handleChange} // Cambiar el valor seleccionado
        >
          {/* Opciones del dropdown con nombres completos */}
          <Option value="es">Español</Option>
          <Option value="en">English</Option>
          <Option value="pt">Português</Option>
        </Select>
      ),
    },
  ];

  if (!isVisible) return null;

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            colorBgContainer: "transparent",
            colorBgElevated: "white", // Fondo del dropdown
            colorBorder: "transparent",
            controlHeight: 40,
            fontSize: 16,
          },
        },
      }}
    >
      <Header className="custom_header">
        <div className="custom_header_logo">
          <img className="img_logo" src={Logo} alt="Cenco Malls" />
        </div>
        <div className="custom_menu_container">
          <Menu
            theme="dark"
            className="custom_header_nav"
            mode="vertical"
            items={menuItems}
          />
        </div>
      </Header>
    </ConfigProvider>
  );
};

export default CustomHeader;
