/* eslint-disable @typescript-eslint/quotes */
import { BrowserRouter as Router } from "react-router-dom";
import MainLayout from "./pages/layout";

import "./themes/default.css";

function App() {
  return (
    <Router>
      <MainLayout />
    </Router>
  );
}

export default App;
