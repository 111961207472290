/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/comma-dangle */
/* eslint-disable @typescript-eslint/quotes */
// src/pages/discount/index.tsx
/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useEffect, useState } from "react";
import DOMPurify from "dompurify";
import { Modal } from "antd";
import DiscountCard from "../../components/DiscountCard";
import { DataContext, Discounts } from "../../context/DataContext";
import useWindowSize from "../../hooks/useWindowsSize";
import { motion, AnimatePresence } from "framer-motion";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { fetchApi } from "../../utils/fetchApi";
import { ICard } from "./interfaces/index";
import { useNavigate, useParams } from "react-router";
import CustomHeader from "../../components/Header";

import CloseButton from "../../assets/img/Close_Button.svg";
import Pin from "../../assets/img/map-pin.svg";
import IconDiscount from "../../assets/img/rosette-discount.svg";

import "./discount.css";
import dict from "../../dict";

const DiscountPage: React.FC = () => {
  const { getDiscounts, setClient, setLanguage, dataState } =
    useContext(DataContext);
  const { language } = dataState;

  const navigate = useNavigate();

  const { clientId } = useParams<{ clientId: string }>();

  const disc = dict.discounts[language as keyof typeof dict.discounts];

  const [discounts, setDiscounts] = useState<Discounts[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [selectedCategory, setSelectedCategory] = useState<string>("All");
  const [loadingDiscounts, setLoadingDiscounts] = useState<boolean>(true);
  const [loadingClient, setLoadingClient] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [redirectCountdown, setRedirectCountdown] = useState(5);
  const [showHeader, setShowHeader] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDiscount, setSelectedDiscount] = useState<Discounts | null>(
    null
  );
  const size = useWindowSize();

  const isMobile = size.width !== undefined && size.width < 900;

  // Extraer categorías únicas, incluyendo 'All'
  const categories = [
    "All",
    ...Array.from(
      new Set(discounts.map((discount) => discount.brand_categories))
    ),
  ];

  const updateItemsPerPage = () => {
    const width = size.width || 0;

    let columns = 4;
    if (width >= 1200) {
      columns = 4;
    } else if (width >= 900) {
      columns = 3;
    } else {
      columns = 2;
    }

    if (isMobile) {
      setItemsPerPage(discounts.length); // Mostrar todos los descuentos en móvil
    } else {
      setItemsPerPage(columns * 2); // Dos filas
    }

    setCurrentPage(1); // Reiniciar la página actual al cambiar el tamaño
  };

  useEffect(() => {
    updateItemsPerPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size.width, discounts.length, isMobile]);

  // Filtrar descuentos según la categoría seleccionada
  const filteredDiscounts =
    selectedCategory === "All"
      ? discounts
      : discounts.filter(
          (discount) => discount.brand_categories === selectedCategory
        );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredDiscounts.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = isMobile
    ? 1
    : Math.ceil(filteredDiscounts.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const getDiscountsFromApi = async (_mall = "costanera") => {
    try {
      const response = await getDiscounts("costanera");
      setDiscounts(response.data?.length > 0 ? response.data : []);
    } catch (err) {
      setError("Failed to load discounts. Please try again later.");
    } finally {
      setLoadingDiscounts(false);
    }
  };

  const getDataCard = async () => {
    try {
      if (!clientId) {
        throw new Error("No clientId provided in route parameters.");
      }
      const response: ICard = await fetchApi(`/card/client/${clientId}`, "GET");
      const parseCard: ICard = {
        ...response,
        expirationDate:
          typeof response.expirationDate === "string"
            ? new Date(response.expirationDate)
            : response.expirationDate,
        emitDate:
          typeof response.emitDate === "string"
            ? new Date(response.emitDate)
            : response.emitDate,
      };
      setClient(parseCard);
      setLanguage(parseCard.client.lang || "es");
    } catch (err) {
      console.log(err);
      setError("Failed to load card data.");
    } finally {
      setLoadingClient(false);
    }
  };

  useEffect(() => {
    getDiscountsFromApi();
    getDataCard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Redirigir a la página principal después de 5 segundos si hay un error
  useEffect(() => {
    if (error) {
      console.log(error);
      const countdownTimer = setInterval(() => {
        setRedirectCountdown((prev) => prev - 1);
      }, 1000);

      const redirectTimer = setTimeout(() => {
        navigate(`/${language}`);
      }, 5000);

      return () => {
        clearInterval(countdownTimer);
        clearTimeout(redirectTimer);
      };
    }
  }, [error, navigate, language]);

  useEffect(() => {
    if (redirectCountdown <= 0) {
      setRedirectCountdown(0);
    }
  }, [redirectCountdown]);

  // Reiniciar página y desplazar al top al cambiar la categoría
  useEffect(() => {
    setCurrentPage(1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [selectedCategory]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 150) {
        // Puedes ajustar el valor según tus necesidades
        setShowHeader(true);
      } else {
        setShowHeader(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Función para mostrar el modal
  const showModal = (discount: Discounts) => {
    setSelectedDiscount(discount);
    setIsModalVisible(true);
  };

  // Función para cerrar el modal
  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedDiscount(null);
  };

  const cleanDescription = () => {
    if (!selectedDiscount) {
      return "";
    }
    const resp = selectedDiscount.description.replace(/<[^>]*>?/gm, "");
    return DOMPurify.sanitize(resp, { USE_PROFILES: { html: true } });
  };

  // Determinar el estado de carga general
  const loading = loadingDiscounts || loadingClient;

  return (
    <div className="discountContent">
      <div className="discountBody">
        <CustomHeader isVisible={showHeader} />
        {error ? (
          <div className="error-message">
            <h2>{disc.clientNotFound}</h2>
            <p>
              {disc.errorMsg} {redirectCountdown} {disc.seconds}.
            </p>
            <button onClick={() => navigate(`/${language}`)}>
              {disc.goHome}
            </button>
          </div>
        ) : (
          <>
            {/* Pills de categorías */}
            {discounts.length > 0 && (
              <div className="categoryPills">
                {categories.map((category) => (
                  <button
                    key={category}
                    className={`pill ${
                      selectedCategory === category ? "active" : ""
                    }`}
                    onClick={() => setSelectedCategory(category)}
                    aria-pressed={selectedCategory === category}
                    role="button"
                  >
                    {category}
                  </button>
                ))}
              </div>
            )}
            <AnimatePresence mode="wait">
              <motion.div
                key={`${selectedCategory}-${currentPage}`}
                className="discountContainCards"
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.5 }}
              >
                {loading ? (
                  // Mostrar Skeletons mientras carga
                  Array.from({ length: itemsPerPage }).map((_, index) => (
                    <div key={index} className="discountCard">
                      <Skeleton height={100} width={100} />
                      <Skeleton height={20} style={{ marginTop: 10 }} />
                      <Skeleton
                        height={20}
                        width={"80%"}
                        style={{ marginTop: 5 }}
                      />
                    </div>
                  ))
                ) : discounts.length === 0 ? (
                  <div className="no-discounts">
                    <h2>{disc.noDiscounts}</h2>
                  </div>
                ) : (
                  currentItems.map((discount) => (
                    <DiscountCard
                      key={discount.id}
                      DiscountsProps={discount}
                      onClick={() => showModal(discount)}
                    />
                  ))
                )}
              </motion.div>
            </AnimatePresence>
            {/* Controles de paginación */}
            {!isMobile && totalPages > 1 && (
              <div className="pagination">
                <button
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                >
                  {disc.previous}
                </button>
                <span>
                  {disc.page} {currentPage} {disc.of} {totalPages}
                </span>
                <button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  {disc.next}
                </button>
              </div>
            )}
          </>
        )}
        <Modal
          open={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          className="discountModal"
          zIndex={9999}
          closeIcon={<img src={CloseButton} alt="close modal" />}
          width={isMobile ? "90%" : "40%"}
        >
          {selectedDiscount ? (
            <div className="discountModalContent">
              <div className="discountModalHeader">
                <img
                  src={`${selectedDiscount.image}`}
                  alt={`${selectedDiscount.brand_name}`}
                />
                <div>
                  <h2>
                    {selectedDiscount.discount_value}{" "}
                    {selectedDiscount.discount_type === "percentual"
                      ? "% OFF"
                      : "$CLP OFF"}
                  </h2>
                  <span>{selectedDiscount.brand_name}</span>
                </div>
              </div>
              <div className="discountModalBody">
                <div dangerouslySetInnerHTML={{ __html: cleanDescription() }} />
                <div className="discountModalLocation">
                  <img src={Pin} alt="pin location" />{" "}
                  <strong>
                    {disc.location} {selectedDiscount.store_location}
                  </strong>
                </div>
                <div className="discountModalInfo">
                  <img src={IconDiscount} alt="discount icon" />
                  <span>{disc.msgDiscounts}</span>
                </div>
              </div>
            </div>
          ) : (
            <p>{disc.noInfo}.</p>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default DiscountPage;
