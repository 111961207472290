/* eslint-disable import/no-named-as-default-member */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { urls } from '../config/urls';


const BASE_URL = window.location.hostname.includes('dev') || window.location.hostname.includes('localhost') ? urls.api : urls.apiProd;
const BASE_URL_AQUIA = window.location.hostname.includes('dev') || window.location.hostname.includes('localhost') ? urls.apiAquia : urls.apiAquiaProd;


//connect to api with axios or fetch
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const axiosInstanceAquia = axios.create({
  baseURL: BASE_URL_AQUIA,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const fetchApi = async (url: string, method: string, data?: any) => {
  const urlFetch = `${BASE_URL}${url}`;
  try {
    const response = await axiosInstance(urlFetch, {
      method,
      headers: {
        'Content-Type': 'application/json',
      },

      data: data ? data : undefined,
    });
    return response.data;

  } catch (error: unknown | any) {
    const errorResponse = error.response.data;
    // if (axios.isAxiosError(error)) {
    //   console.error('Error fetching data', error.message);
    // } else {
    //   console.error('Unknown error occurred', error);
    //   throw new Error('An unknown error occurred');
    // }
    return errorResponse;
  }
};

export const fetchApiAquia = async (url: string, method: string, data?: any) => {
  const urlFetch = `${BASE_URL_AQUIA}${url}`;
  try {
    const response = await axiosInstanceAquia(urlFetch, {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data ? data : undefined,
    });

    return response.data;

  } catch (error: unknown) {
    console.error(error);
    if (axios.isAxiosError(error)) {
      console.error('Error fetching data', error.message);
      //return error.response?.data;
    } else {
      console.error('Unknown error occurred', error);
      throw new Error('An unknown error occurred');
    }
    return [];
  }
};

//TODO: Mejorar esta función para que sea más genérica
