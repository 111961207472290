import { useReducer, useMemo } from 'react';
import { FieldType, IOneTrustData } from '../components/Home/registrationForm';
import { DataContext, RespDiscounts } from './DataContext';
import { dataReducer } from './dataReducer';
import { fetchApi } from '../utils/fetchApi';
import { ICard } from '../pages/discount/interfaces';

import { IFormDraft } from '../components/Home/IFormDraft';

const INITIAL_STATE = {
  language: 'es',
  fieldData: {},
  client: null,
  darkMode: false,
  formDraft: {} as IFormDraft,
};
interface Props {
  children: JSX.Element | JSX.Element[];
}

export const DataProvider = ({ children }: Props) => {
  const [dataState, dispatch] = useReducer(dataReducer, INITIAL_STATE);

  const sendFormRegister = async (data: FieldType) => {
    const { lang } = data;
    const resp = await fetchApi(`/client?lang=${lang}`, 'POST', data);
    return resp;
  };

  const setLanguage = (language: string) => {
    dispatch({ type: 'setLanguage', payload: language });
  };

  const getDiscounts = async (mall: string) => {
    const resp: RespDiscounts = await fetchApi(`/client/discount/${mall}`, 'GET');
    return resp;
  };

  const setClient = (client: ICard | null) => {
    dispatch({ type: 'setClient', payload: client });
  };

  const setDarkMode = (darkMode: boolean) => {
    dispatch({ type: 'setDarkmode', payload: darkMode });
  };

  const sendDataToOneTrust = async (data: IOneTrustData) => {
    const resp = await fetchApi('/client/onetrust', 'POST', data);
    return resp;
  };

  const setFormDraft = (draft: Partial<IFormDraft>) => {
    console.log('draft en provider', draft);
    dispatch({ type: 'setFormDraft', payload: draft });
  };

  const contextValue = useMemo(() => ({
    dataState,
    sendFormRegister,
    setLanguage,
    getDiscounts,
    setClient,
    setDarkMode,
    sendDataToOneTrust,
    setFormDraft,
  }), [dataState]);

  return (
    <DataContext.Provider value={contextValue}>
      {children}
    </DataContext.Provider>
  );


};
