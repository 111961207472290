/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/quotes */
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom"; // Ya no necesitas el <Router> aquí
import { ConfigProvider, Layout } from "antd";
import "./layout.css";
import HomePage from "./../home";
import SuccessPage from "./../success"; // Importar el nuevo componente de éxito
import CustomHeader from "../../components/Header";
import CustomSidebar from "../../components/Sidebar";
import CustomFooter from "../../components/Footer";
import DiscountPage from "../discount";
import DiscountHeader from "../../components/HeaderDiscount";
import { useContext, useEffect } from "react";
import { DataContext } from "../../context/DataContext";
import TyCPage from "../tyc";

const MainLayout = () => {
  const { lang } = useParams();
  const { setLanguage, dataState } = useContext(DataContext);
  const { language, darkMode } = dataState;
  const location = useLocation(); // Hook para obtener la ruta actual
  const isSuccessPage = location.pathname.includes("/success"); // Detectar si estamos en la ruta de éxito
  const isDiscountPage = location.pathname.includes("/discount"); // Detectar si estamos en una ruta que contiene 'discount'

  useEffect(() => {
    const knownLangs = ["es", "en", "pt"];

    if (lang && lang !== language && knownLangs.includes(lang)) {
      setLanguage(lang);
    }

    const pathname = location.pathname;
    const splitPathname = pathname.split("/");

    if (
      !lang &&
      splitPathname.length === 2 &&
      knownLangs.includes(splitPathname[1])
    ) {
      setLanguage(splitPathname[1]);
    }
  }, [lang]);

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Poppins, sans-serif",
          colorBorder: "transparent",
          borderRadius: 12,
          colorPrimary: "#240070",
        },
        components: {
          Layout: {
            colorBgLayout: "transparent",
          },
        },
      }}
    >
      <Layout className={'layout-main' + (darkMode ? ' dark-modal' : '')}>
        {/* Header fijo en la parte superior */}
        {!isDiscountPage ? (
          <CustomHeader isVisible={true} />
        ) : (
          <DiscountHeader isVisible={true} />
        )}

        {/* Layout del cuerpo */}
        <Layout
          className={`layout-body ${isSuccessPage ? "success-page" : ""}`}
        >
          {!isSuccessPage && !isDiscountPage && (
            <CustomSidebar isVisible={true} />
          )}

          <Layout className="layout-content">
            {/* Contenido principal */}
            <Layout.Content>
              <Routes>
                <Route
                  path="/"
                  element={
                    <Navigate to={`/${language ? language : "es"}`} replace />
                  }
                />
                <Route
                  path="/success"
                  element={
                    <Navigate
                      to={`/${language ? language : "es"}/success`}
                      replace
                    />
                  }
                />
                <Route
                  path="/tyc"
                  element={
                    <Navigate
                      to={`/${language || "es"}/tyc`}
                      replace
                    />
                  }
                />
                <Route
                  path="/discount/:clientId"
                  element={
                    <Navigate
                      to={`/${language ? language : "es"}/discount/:clientId`}
                      replace
                    />
                  }
                />
                <Route path="/:lang" element={<HomePage />} />
                <Route path="/:lang/success" element={<SuccessPage />} />
                <Route path="/:lang/tyc" element={<TyCPage />} />
                <Route
                  path="/:lang/discount/:clientId"
                  element={<DiscountPage />}
                />
              </Routes>
            </Layout.Content>
            {/* Footer */}
            {!isSuccessPage && (
              <Layout.Footer className={'layout-footer' + (darkMode ? ' dark-modal' : '')}>
                <CustomFooter isVisible={true} />
              </Layout.Footer>
            )}
          </Layout>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default MainLayout;
