/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext } from 'react';
import { FieldType, IOneTrustData } from '../components/Home/registrationForm';
import { ICard } from '../pages/discount/interfaces';
import { IFormDraft } from '../components/Home/IFormDraft';

export interface DataState {
  language: string;
  client: ICard | null;
  darkMode: boolean;
  formDraft: IFormDraft;
}

export interface SendFromRegisterResult {
  ok: boolean,
  data?: FieldType,
  error?: string,
  message?: string,
}

export interface Discounts {
  id: string;
  mall: string;
  brand_name: string;
  brand_categories: string;
  brand_sub_categories: string;
  image: string;
  subtitle: string;
  description: string;
  link_text: string;
  control: string;
  store_location: string;
  discount_type: string;
  discount_value: string;
}

export interface RespDiscounts {
  ok: boolean;
  message: string;
  data: Discounts[];
}

interface Props {
  dataState: DataState;
  sendFormRegister: (data: FieldType) => Promise<SendFromRegisterResult>;
  setLanguage: (language: string) => void;
  getDiscounts: (mall: string) => Promise<RespDiscounts>;
  setClient: (client: ICard | null) => void;
  setDarkMode: (darkMode: boolean) => void;
  sendDataToOneTrust: (data: IOneTrustData) => Promise<any>;
  setFormDraft: (draft: Partial<IFormDraft>) => void;
}

export const DataContext = createContext<Props>({} as Props);
