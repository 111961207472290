import React, { useContext, useEffect, useState } from 'react';
import { DataContext } from '../../context/DataContext';
import tyc from '../../dict/tyc';
import politica from '../../dict/politica';
import accesibilidad from '../../dict/accesibility';
import { MobileHeader } from '../../components/Header/mobileHeader';
import { Button, ConfigProvider, Modal, Tabs, Tooltip } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';

import Increase from '../../assets/img/text-increase.svg';
import Decrease from '../../assets/img/text-decrease.svg';
import IconContrast from '../../assets/img/contrast.svg';
import Accesibility from '../../assets/img/accessibility_black.2b8e3235.svg';
import Download from '../../assets/img/download.svg';
import ArrowLeft from '../../assets/img/arrowLeft.svg';

import './tyc.css';
import { useLocation, useNavigate } from 'react-router';

const TyCPage: React.FC = () => {

  const location = useLocation();
  const state = location.state as { tab?: 'tyc' | 'politica' };

  const { setDarkMode, dataState } = useContext(DataContext);
  const { language, darkMode } = dataState;

  const [isModalAccesibilityVisible, setIsModalAccesibilityVisible] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [fontSize, setFontSize] = useState(1);
  const [activeTab, setActiveTab] = useState<'tyc' | 'politica'>('tyc');

  const homTyC = tyc[language as keyof typeof tyc];
  const homPolitica = politica[language as keyof typeof politica];
  const homAccesibility = accesibilidad[language as keyof typeof accesibilidad];

  const navigate = useNavigate();

  useEffect(() => {
    if (state?.tab === 'tyc' || state?.tab === 'politica') {
      setActiveTab(state.tab);
    }
  }, [state]);

  const handleDownload = () => {
    const link = document.createElement('a');
    if (activeTab === 'tyc') {
      link.href = `/doc/tyc_${language}.pdf`;
      link.download = `tyc_${language}.pdf`;
    } else {
      link.href = `/doc/Politicas_${language}.pdf`;
      link.download = `Politicas_${language}.pdf`;
      console.log('politica', activeTab);
    }
    link.click();
  };

  const increaseFont = () => {
    setFontSize((prev) => (prev < 2 ? prev + 0.25 : prev));
  };

  const decreaseFont = () => {
    setFontSize((prev) => (prev > 0.75 ? prev - 0.25 : prev));
  };


  return (
    <ConfigProvider
        theme={{
          components: {
            Tabs: {
              inkBarColor: '#00B1FF',
              itemColor: darkMode ? 'gray' : '#584E80',
              itemSelectedColor: darkMode ? '#fff' : '#251660',
              itemHoverColor: darkMode ? '#rgb(146, 148, 152)' : '#251660',
            },
            Button: {
              colorBorder: '#584E80',
            },
          },
        }}
    >
        <div className={'home-container' + (isDarkMode ? ' dark-modal' : '')}>
            <MobileHeader />
            <Button
                icon={<img src={ArrowLeft} alt="ArrowLeft" />}
                onClick={() => navigate('/')}
                style={{ padding: '8px 16px' }}
            >
                {homTyC.mayorButton}
            </Button>
            <h1 className='h1-tyc'>{homTyC.mayorTitle}</h1>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                marginTop: '10px',
                alignItems: 'flex-start',
              }}
            >
              <div className='div-tyc'>
                <Button
                    key="increaseFont"
                    onClick={increaseFont}
                    className='button-tyc'
                >
                    <img src={Increase} alt="A+" />
                </Button>
                <p>{ homAccesibility.grown }</p>
              </div>

              <div className="div-tyc">
                <Button
                    key="decreaseFont"
                    onClick={decreaseFont}
                    className='button-tyc'
                >
                    <img src={Decrease} alt="A-" />
                </Button>
                <p>{ homAccesibility.decrease }</p>
              </div>

              <div className="div-tyc">
                <Button
                    key="toggleTheme"
                    type="default"
                    className='button-tyc'
                    onClick={() => {
                      setDarkMode(!isDarkMode);
                      setIsDarkMode(!isDarkMode);
                    }}
                >
                    <img src={IconContrast} alt="Contrast" />
                </Button>
                <p>{ homAccesibility.contrast }</p>
              </div>

              <div className="div-tyc">
                <Button
                    key="Accesibility"
                    onClick={() => setIsModalAccesibilityVisible(true)}
                    className='button-tyc'
                >
                    <img src={Accesibility} alt="Accesibility" />
                </Button>
                <p>{ homAccesibility.accesibility }</p>
              </div>

              <div className="div-tyc">
                <Button
                    key="Download"
                    //onClick={() => setIsModalAccesibilityVisible(true)}
                    onClick={handleDownload}
                    className='button-tyc'
                >
                    <img src={Download} alt="Download" />
                </Button>
                <p>{ homAccesibility.download }</p>
              </div>

            </div>
            <Tabs
                onChange={(key) => {
                  setActiveTab(key as 'tyc' | 'politica');
                }}
                activeKey={activeTab}
            >
                <TabPane 
                    tab={
                        <Tooltip
                            title={homTyC.title}
                        >
                            {homTyC.title}
                        </Tooltip>
                    } 
                    key="tyc">
                    <div
                        className='div-body-tyc'
                        style={{ fontSize: `${fontSize}rem` }}
                        dangerouslySetInnerHTML={{ __html: homTyC.body }} 
                    />
                </TabPane>

                <TabPane
                    tab={
                        <Tooltip
                            title={homPolitica.title}
                        >
                            {homPolitica.title}
                        </Tooltip>
                    } 
                    key="politica"
                >
                    <div
                        className='div-body-tyc'
                        style={{ fontSize: `${fontSize}rem` }}
                        dangerouslySetInnerHTML={{ __html: homPolitica.body }} 
                    />
                </TabPane>
            </Tabs>
        </div>

        <Modal
            title={homAccesibility.title}
            open={isModalAccesibilityVisible}
            onOk={() => setIsModalAccesibilityVisible(false)}
            onCancel={() => setIsModalAccesibilityVisible(false)}
            className={isDarkMode ? 'dark-modal' : ''}
            width={window.innerWidth < 768 ? '90%' : '50%'}
            style={{ 
              fontSize: `${fontSize}rem`,
            }}
            footer={[
            <Button 
                key="close" 
                type="primary" 
                onClick={() => setIsModalAccesibilityVisible(false)}
            >
                {homAccesibility.close} {/* Por ejemplo: "Cerrar" */}
            </Button>,
            ]}
        >
            <div
                style={{
                  maxHeight: window.innerWidth < 768 ? '50vh' : '65vh',
                  overflowY: 'auto',
                }}
            >
                <div 
                    dangerouslySetInnerHTML={{ __html: homAccesibility.body }}  
                />
            </div>
        </Modal>
    </ConfigProvider>
  );
};

export default TyCPage;
