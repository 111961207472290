/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { decode } from 'html-entities';

import './discountCard.css';
import { Discounts } from '../../context/DataContext';

interface Props {
  DiscountsProps: Discounts;
  onClick: () => void;
}

const DiscountCard: React.FC<Props> = ({ DiscountsProps, onClick }) => {
  // Decodifica el brand_name
  const decodedBrandName = decode(DiscountsProps.brand_name);
  return (
        <div className="discountCard" onClick={onClick} style={{ cursor: 'pointer' }}>
            <div className="discountCardImage">
                <img src={DiscountsProps.image} alt={`discount for ${DiscountsProps.brand_name}`} />
            </div>
            <div className="discountCardText">
                <h3>{decodedBrandName}</h3>
                <h2 className="discountOffer">{DiscountsProps.subtitle}</h2>
            </div>
        </div>
  );
};

export default DiscountCard;
