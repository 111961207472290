import React, { useContext } from 'react';

import Logo from '../../assets/img/cenco_mall_wide.svg';
import LogoContrast from '../../assets/img/cenco_mall_wide_contrast.svg';

import './footer.css';
import { DataContext } from '../../context/DataContext';

interface FooterProps {
  isVisible: boolean;
}

const CustomFooter: React.FC<FooterProps> = ({ isVisible }) => {

  const { dataState } = useContext(DataContext);
  const { darkMode } = dataState;

  const year = new Date().getFullYear();

  if (!isVisible) return null;

  return (
    <div className='custom-footer'>
      <p>@{ year } Cenco Malls. Todos los derechos reservados.</p>
      <div className='custom-footer-img'>
        <img src={ 
          darkMode ? LogoContrast : Logo 
          } 
          alt="cencomalls" 
        />
      </div>
    </div>
  );
};

export default CustomFooter;
