/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useContext, useEffect, useState } from 'react';
import { ConfigProvider, Menu, Select } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import Logo from '../../assets/img/MarcaCenco.svg';
import './discountHeader.css';
import { DataContext } from '../../context/DataContext';

import GrupoPersonas from '../../assets/img/grupopersonas.webp';
import SvgBack from '../../assets/img/CencoDecorationHeader.svg';
import dict from '../../dict';

//const { Header } = Layout;
const { Option } = Select;

interface HeaderProps {
  isVisible: boolean;
}

const DiscountHeader: React.FC<HeaderProps> = ({ isVisible }) => {
  const { setLanguage, dataState } = useContext(DataContext);
  const { client, language } = dataState;

  const [open, setOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    language ? language : 'es',
  );

  const disc = dict.discounts[language as keyof typeof dict.discounts];

  useEffect(() => {
    if (client && client.client && client.client.lang) {
      setSelectedLanguage(client.client.lang);
    } else {
      setSelectedLanguage('es');
    }
  }, [client]);

  useEffect(() => {
    language && setSelectedLanguage(language);
  }, [language]);

  // Función para manejar el cambio de selección
  const handleChange = (value: string) => {
    setLanguage(value ? value : 'es');
    setSelectedLanguage(value);
  };

  // Función para mostrar la abreviatura correspondiente al valor seleccionado
  const renderSelectedValue = () => {
    switch (selectedLanguage) {
      case 'es':
        return 'ES';
      case 'en':
        return 'EN';
      case 'pt':
        return 'PT';
      default:
        return 'ES';
    }
  };

  const menuItems = [
    {
      className: 'discount_custom_header_nav_item',
      key: '1',
      icon: <GlobalOutlined style={{ fontSize: '20px' }} />, // Aumentamos el tamaño del ícono
      label: (
        <Select
          value={renderSelectedValue()} // Mostrar la abreviatura seleccionada
          style={{ width: 70 }}
          popupClassName="discount_custom_select_dropdown"
          onDropdownVisibleChange={() => setOpen(!open)}
          suffixIcon={
            <span className={`discount_custom-arrow ${open ? 'rotate' : ''}`}>
              &#9660;
            </span>
          }
          dropdownStyle={{
            width: '150px',
            zIndex: 1000,
          }}
          dropdownAlign={{
            points: ['tc', 'bc'], // Alineación superior del dropdown con la parte inferior del Select
            offset: [-20, 8], // Desplazamiento vertical para evitar solapamiento
          }}
          onChange={handleChange} // Cambiar el valor seleccionado
        >
          {/* Opciones del dropdown con nombres completos */}
          <Option value="es">Español</Option>
          <Option value="en">English</Option>
          <Option value="pt">Português</Option>
        </Select>
      ),
    },
  ];

  if (!isVisible) return null;

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            colorBgContainer: 'transparent',
            colorBgElevated: 'white', // Fondo del dropdown
            colorBorder: 'transparent',
            controlHeight: 40,
            fontSize: 16,
          },
        },
      }}
    >
      <div className="discount_custom_header_main">
        <div className="discount_custom_header_top">
          <div className="discount_custom_header_logo">
            <img className="img_logo" src={Logo} alt="Cenco Malls" />
          </div>
          <div className="discount_custom_menu_container">
            <Menu
              theme="dark"
              className="discount_custom_header_nav"
              mode="vertical"
              items={menuItems}
            />
          </div>
        </div>
        <div className="discount_custom_header_body">
          <div>
            <h1>OnTour</h1>
            <table className="discount_custom_header_table">
              <thead>
                <tr>
                  <th>{disc.name}</th>
                  <th>{disc.validate}</th>
                  <th>{disc.card}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-label={disc.name}>
                    { client?.client?.name && 
                      client?.client.lastname 
                      ? client.client.name + ' ' + client.client.lastname 
                      : 'N/A'
                    }
                  </td>
                  <td data-label={disc.validate}>
                    {client
                      ? client.expirationDate
                        ? client.expirationDate.toLocaleDateString('es-ES', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                        })
                        : 'S/I'
                      : 'S/I'}
                  </td>
                  <td data-label={disc.card}>{client?.cardNumber}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <img
            className="discount_custo_header_back"
            src={SvgBack}
            alt="back"
          />
          <div className="discount_custom_header_guys">
            <img src={GrupoPersonas} alt="chicos" />
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default DiscountHeader;
