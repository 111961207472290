/* eslint-disable @typescript-eslint/quotes */

const dict = {
  home: {
    es: {
      title: "OnTour",
      welcome:
        "Registrate al programa On Tour y accede a ofertas únicas para turistas.",
      instructions:
        "Rellena el formulario y recibe tu tarjeta digital para disfrutar de grandes descuentos.",
      name: "Nombre",
      errorName: "Por favor ingrese su nombre",
      errorName2: "El nombre no puede exceder los 40 caracteres",
      lastName: "Apellido",
      errorLastName: "Por favor ingrese su apellido",
      errorLastName2: "El apellido no puede exceder los 40 caracteres",
      email: "Correo Electrónico",
      errorEmail: "Por favor ingrese su Correo Electrónico",
      errorEmail2: "Por favor ingrese un correo válido",
      errorEmail3: "El correo no puede exceder los 100 caracteres",
      document: "N° Pasaporte",
      errorDocument: "Por favor ingrese su Num identificación",
      errorDocument2:
        "El número de identificación no puede exceder los 30 caracteres",
      arrival: "Fecha de Llegada",
      errorArrival: "Por favor ingrese su fecha de llegada",
      country: "País de Origen",
      errorCountry: "Por favor ingrese su país de origen",
      searchCountry: "Buscar país",
      genre: "Género",
      male: "Masculino",
      female: "Femenino",
      other: "Otro",
      errorGenre: "Por favor seleccione un género",
      promo: "Acepto recibir ofertas y promociones.",
      terms: "Acepto",
      termsTyC: "términos y condiciones.",
      termsAnd: "y",
      termsPol: "políticas de privacidad.",
      errorTerms: "Por favor acepte los términos y condiciones",
      accesibilty: "¿Qué es accesibilidad?",
      submit: "Registrate",
    },
    en: {
      title: "OnTour",
      welcome:
        "Sign up for the On Tour program and access unique offers for tourists.",
      instructions:
        "Fill out the form and receive your digital card to enjoy great discounts.",
      name: "Name",
      errorName: "Please enter your name",
      errorName2: "The name cannot exceed 40 characters",
      lastName: "Last Name",
      errorLastName: "Please enter your last name",
      errorLastName2: "The last name cannot exceed 40 characters",
      email: "Email",
      errorEmail: "Please enter your email",
      errorEmail2: "Please enter a valid email",
      errorEmail3: "The email cannot exceed 100 characters",
      document: "Passport N°",
      errorDocument: "Please enter your identification number",
      errorDocument2: "The identification number cannot exceed 30 characters",
      arrival: "Arrival Date",
      errorArrival: "Please enter your arrival date",
      country: "Country",
      errorCountry: "Please enter your country",
      searchCountry: "Search country",
      genre: "Gender",
      male: "Male",
      female: "Female",
      other: "Other",
      errorGenre: "Please select a genre",
      promo: "I accept to receive offers and promotions.",
      terms: "I accept",
      termsTyC: "terms and conditions.",
      termsAnd: "and",
      termsPol: "privacy policies.",
      errorTerms: "Please accept the terms and conditions",
      accesibilty: "What is accessibility?",
      submit: "Sign up",
    },
    pt: {
      title: "OnTour",
      welcome:
        "Inscreva-se no programa On Tour e acesse ofertas únicas para turistas.",
      instructions:
        "Preencha o formulário e receba seu cartão digital para desfrutar de grandes descontos.",
      name: "Nome",
      errorName: "Por favor, insira seu nome",
      errorName2: "O nome não pode exceder 40 caracteres",
      lastName: "Sobrenome",
      errorLastName: "Por favor insira seu sobrenome",
      errorLastName2: "O sobrenome não pode exceder 40 caracteres",
      email: "E-mail",
      errorEmail: "Por favor insira seu e-mail",
      errorEmail2: "Por favor insira um e-mail válido",
      errorEmail3: "O e-mail não pode exceder 100 caracteres",
      document: "N° Passaporte",
      errorDocument: "Por favor insira seu número de identificação",
      errorDocument2:
        "O número de identificação não pode exceder 30 caracteres",
      arrival: "Data de Chegada",
      errorArrival: "Por favor insira sua data de chegada",
      country: "País de Origem",
      errorCountry: "Por favor insira seu país de origem",
      searchCountry: "Procurar país",
      genre: "Gênero",
      male: "Masculino",
      female: "Feminino",
      other: "Outro",
      errorGenre: "Por favor selecione um gênero",
      promo: "Aceito receber ofertas e promoções.",
      terms: "Aceito os termos e condições.",
      termsTyC: "termos e condições.",
      termsAnd: "e",
      termsPol: "políticas de privacidade.",
      errorTerms: "Por favor aceite os termos e condições",
      accesibilty: "O que é acessibilidade?",
      submit: "Inscrever-se",
    },
  },
  success: {
    es: {
      registerTitle: "¡Registro exitoso!",
      thanks: "gracias por registrarte.",
      email: "Te hemos enviado un correo de registro a ",
      important: "Importante",
      importantInfo:
        "Para obtener tu tarjeta, por favor sigue los pasos a continuación:",
      step1: "Visita nuestro centro de atención al cliente:",
      step1Info1: "Nivel 1, Cenco Costanera.",
      step1Info2:
        "Lunes a sábado de 08:00 a 20:00 hrs, domingo de 09:00 a 20:00 hrs. ",
      step2: "Verifica tu identidad:",
      step2Info1:
        "Presenta tu pasaporte o identificación utilizada en el registro.",
      step2Info2: "Presenta el correo de confirmación que te hemos enviado.",
      setp3: "Recepción de la tarjeta digital",
      setp3Info:
        "Una vez verificada tu información, recibirás tu tarjeta de descuento digital en tu correo electrónico.",
    },
    en: {
      registerTitle: "Successful registration!",
      thanks: "thanks for registering.",
      email: "We have sent you a registration email to ",
      important: "Important",
      importantInfo: "To get your card, please follow the steps below:",
      step1: "Visit our customer service center:",
      step1Info1: "Level 1, Cenco Costanera.",
      step1Info2:
        "Monday to Saturday from 08:00 to 20:00 hrs, Sunday from 09:00 to 20:00 hrs.",
      step2: "Verify your identity:",
      step2Info1:
        "Present your passport or identification used in the registration.",
      step2Info2: "Present the confirmation email we sent you.",
      setp3: "Reception of the digital card",
      setp3Info:
        "Once your information has been verified, you will receive your digital discount card in your email.",
    },
    pt: {
      registerTitle: "Registro bem-sucedido!",
      thanks: "obrigado por se registrar.",
      email: "Enviamos um e-mail de registro para ",
      important: "Importante",
      importantInfo: "Para obter seu cartão, siga as etapas abaixo:",
      step1: "Visite nosso centro de atendimento ao cliente:",
      step1Info1: "Nível 1, Cenco Costanera.",
      step1Info2:
        "Segunda a sábado das 8h00 às 20h00, domingo das 9h00 às 20h00.",
      step2: "Verifique sua identidade:",
      step2Info1:
        "Apresente seu passaporte ou identificação usada no registro.",
      step2Info2: "Apresente o e-mail de confirmação que enviamos a você.",
      setp3: "Recepção do cartão digital",
      setp3Info:
        "Depois que suas informações forem verificadas, você receberá seu cartão de desconto digital em seu e-mail.",
    },
  },
  discounts: {
    es: {
      name: "Nombre",
      validate: "Valido",
      card: "Num. Card",
      previous: "Anterior",
      next: "Siguiente",
      page: "Página",
      of: "de",
      clientNotFound: "Cliente no encontrado",
      errorMsg:
        "El ID proporcionado no es válido. Serás redirigido a la página principal en",
      seconds: "segundos",
      goHome: "Ir a la página principal ahora",
      location: "Ubicación",
      msgDiscounts: "Muestra el descuento en la tienda para hacerlo efectivo",
      noInfo: "No hay información disponible",
      noDiscounts: "No hay descuentos disponibles",
    },
    en: {
      name: "Name",
      validate: "Valid",
      card: "Card Num",
      previous: "Previous",
      next: "Next",
      page: "Page",
      of: "of",
      clientNotFound: "Client not found",
      errorMsg:
        "The provided ID is not valid. You will be redirected to the main page in",
      seconds: "seconds",
      goHome: "Go to the main page now",
      location: "Location",
      msgDiscounts: "Show the discount in the store to make it effective",
      noInfo: "No information available",
      noDiscounts: "No discounts available",
    },
    pt: {
      name: "Nome",
      validate: "Válido",
      card: "Num. Cartão",
      previous: "Anterior",
      next: "Próximo",
      page: "Página",
      of: "de",
      clientNotFound: "Cliente não encontrado",
      errorMsg:
        "O ID fornecido não é válido. Você será redirecionado para a página principal em",
      seconds: "segundos",
      goHome: "Ir para a página principal agora",
      location: "Localização",
      msgDiscounts: "Mostre o desconto na loja para torná-lo eficaz",
      noInfo: "Nenhuma informação disponível",
      noDiscounts: "Nenhum desconto disponível",
    },
  },
};

export default dict;
