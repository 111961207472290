import { IFormDraft } from '../components/Home/IFormDraft';
import { ICard } from '../pages/discount/interfaces';

/* eslint-disable @typescript-eslint/no-explicit-any */
type Actions =
| { type: 'cleanUser' }
| { type: 'setLanguage', payload: string } // Nueva acción para el idioma
| { type: 'setFieldData', payload: any } // Acción para disponibilizar la info
| { type: 'setClient', payload: ICard | null } // Acción para disponibilizar el cliente  
| { type: 'setDarkmode', payload: boolean } // Acción para disponibilizar el modo oscuro
| { type: 'setFormDraft', payload: Partial<IFormDraft> }; // Acción para disponibilizar el borrador del formulario

export const dataReducer = (state: any, action: Actions) => {
  switch (action.type) {
    // Nuevo caso para cambiar el idioma
    case 'setLanguage':
      return {
        ...state,
        language: action.payload, // Guardamos el nuevo idioma en el estado
      };

      // Caso para disponibilizar o actualizar información
    case 'setFieldData':
      return {
        ...state,
        fieldData: action.payload, // Guardamos o actualizamos la info
      };

    case 'setClient':
      return {
        ...state,
        client: action.payload, // Guardamos el cliente en el estado
      };

    case 'setDarkmode':
      return {
        ...state,
        darkMode: action.payload, // Guardamos el modo oscuro en el estado
      };

    case 'setFormDraft':
      return {
        ...state,
        formDraft: {
          ...state.formDraft,
          ...action.payload, // Guardamos o actualizamos el borrador del formulario
        },
      };

      // Otros casos existentes ...

    default:
      return state;
  }
};
