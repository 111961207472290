const politica = {
  es: {
    title: 'POLÍTICA DE PRIVACIDAD Y TRATAMIENTO DATOS PERSONALES “ON TOUR”',
    subtitle: 'CENCOSUD SHOPPING S.A.',
    download: 'Descargar',
    close: 'Cerrar',
    body: `<h4>Última actualización, 23 de diciembre de 2024</h4>
    <b>I. CONDICIONES GENERALES</b>
    <b>DECLARACIÓN</b>
    <p>Cencosud Shopping S.A., 76.433.310-1 (en adelante CencoMalls), domiciliada para estos efectos en Av. Kennedy 9001, piso 4, Comuna de Las Condes, Región Metropolitana, ha elaborado esta Política De Privacidad Y Tratamiento De Datos Personales con el propósito que los Usuarios, registrados en la página web https://ontour.cencomalls.com/ (en adelante la “Plataforma”), tengan conocimiento sobre la información personal que se reúne respecto de los mismos, así como la manera como aquella se utiliza y el propósito de su tratamiento. Se deja constancia que cualquier modificación que se realice a esta política será informada oportunamente en el sitio https://ontour.cencomalls.com/. Un texto actualizado de la presente política se mantendrá publicada en esta plataforma.</p>
    <b>2. ALMACENAMIENTO, FINALIDAD, CUSTODIA Y TRATAMIENTO DE LOS DATOS</b>
    <p>Para tener la calidad de “Usuario”, debe completar el formulario en la página web ontour.cencomallstechpro.com, conforme a lo señalados en los Términos y Condiciones, con los siguientes datos:</p>
    <p>• Nombre y apellido</p>
    <p>• Número de pasaporte o DNI</p>
    <p>• Correo electrónico</p>
    <p>• País de residencia</p>
    <p>• Fecha entrada a Chile</p>
    <p>• Género</p>
    <p>El “Usuario” podrá visitar esta plataforma como “Usuario” registrado y como “Usuario” no registrado. Solo podrá acceder a los servicios ofrecidos, como Usuario registrado y, una vez activada la Tarjeta On Tour. La sola visita a esta “plataforma” no impone al “Usuario” obligación alguna. Es importante que el “Usuario” tenga presente que cuando acepta su incorporación a la Plataforma, declara que los antecedentes o datos de carácter personal que proporciona, en adelante la “Información”, son sustancialmente correctos y corresponden a la realidad, debiendo comunicar cualquier modificación o cambio en estos, tan pronto como se produjeren, siendo el “Usuario” personalmente responsable de las consecuencias que pudiere ocasionar su incumplimiento.</p>
    <p>En consideración a lo anterior, el “Usuario” consiente expresamente que, como efecto de su incorporación a la Plataforma, la Información que se recopile, o reciba de los “Usuarios”, a través del formulario de inscripción, podrá ser objeto de tratamiento, entendiendo por tal “……cualquier operación o complejo de operaciones o procesamientos técnicos, de carácter automatizado o no, que permitan recolectar, almacenar, grabar, organizar, elaborar, agrupar, seleccionar, extraer, confrontar, interconectar, disociar o cancelar la información;…”. Además podrá ser objeto de:</p>
    <p><b>1. Disposición:</b> Entendiendo por tal comunicar, ceder, transferir o cancelar datos de carácter personal o utilizarlos en cualquier forma autorizada.</p>
    <p><b>2. Procesamiento:</b> Entendiendo por tal los mecanismos y procesos que permitan brindar al “Usuario” los servicios ofrecidos;</p>
    <p>La Información podrá ser usada para los fines que, a continuación, se expresan:</p>
    <p>1. La mantención de las relaciones contractuales y/o comerciales derivadas del uso de la plataforma y Tarjeta On Tour.</p>
    <p>2. La confección y/o mejora de los productos y/o servicios que ofrece CencoMalls y le prestan a los “Usuario”(s), así como el diseño de nuevos productos y/o servicios para éstos;</p>
    <p>3. Enviar todo tipo de información, tales como antecedentes técnicos, publicidad, ofertas y promoción de productos y/o servicios, ya sean ofrecidos por CencoMalls, o de cualquier otra empresa que forme parte, o llegaran a formar parte del Grupo Cencosud; y</p>
    <p>4. Realizar estudios individuales y/o colectivos de cualquier tipo como, por ejemplo, estudios en materia demográfica, histórica, comportamiento de los “Usuarios” e investigaciones de mercado; cuidando siempre de la reserva y confidencialidad de los datos personales de la manera exigida por la ley.</p>
    <p>CencoMalls podrá disponer y comunicar al resto de las empresas que forman parte, o llegaran a formar parte del Grupo Cencosud; todo o parte de la Información proporcionada por el “Usuario”, caso en el cual su destinatario deberá cumplir con alguno de los siguientes requisitos:</p>
    <p>1. Ser una persona relacionada a la propiedad o gestión de empresas que forman parte, o llegaran a formar parte del Grupo Cencosud;</p>
    <p>2. Ser una filial o controladora de la misma, o una persona jurídica que esté bajo el control común de empresas que forman parte, o llegaran a formar parte, del Grupo Cencosud; y/o de las sociedades con que estas establezcan acuerdos comerciales para el desarrollo de estrategias vinculadas con su giro y/o</p>
    <p>3. Ser una Marca Participante.</p>
    <p>La Plataforma procurará que el destinatario de la referida información garantice que ésta será tratada bajo adecuados parámetros de reserva y que, en el evento que el “Usuario” se oponga al uso de esta, será eliminada de los registros respectivos. Por último, tanto esta plataforma como los citados destinatarios sólo podrán usar la Información con los propósitos informados precedentemente. La Plataforma procurará que el destinatario de la referida información garantice que ésta será tratada bajo adecuados parámetros de reserva y que, en el evento que el “Usuario” se oponga al uso de esta, será oportunamente eliminada de los registros respectivos.</p>
    <b>3. PLAZO DE CONSERVACION DE LOS DATOS</b>
    <p>Cencosud Shopping S.A. conservará los datos de registro del Usuario mientras se requieran para el cumplimiento de una o más de las finalidades singularizadas en el Punto N°2 de esta Política De Privacidad Y Tratamiento De Datos Personales.</p>
    <b>4. DERECHOS DEL “USUARIO”</b>
    <p>El “Usuario” tendrá los derechos de acceso a los datos personales recolectados, así como los derechos de cancelación, rectificación y oposición. Los datos personales de los “Usuarios” deberán ser modificados cuando sean erróneos, inexactos, equívocos o incompletos, o deberán ser eliminados cuando su almacenamiento carezca de fundamento legal o hayan caducado, y todos los demás derechos que confiere la Ley N°19.628 sobre Protección de la Vida Privada, y la Constitución Política de la República. Asimismo, el “Usuario”, tendrá derecho a revocar la autorización de tratamiento, caso en el cual la revocación producirá sus efectos hacia el futuro. Tendrá derecho, además, a solicitar que no se le envíen comunicaciones publicitarias. Para lo anterior, el Usuario deberá enviar una comunicación escrita al correo electrónico mencionado en el Punto 5 de la presente Política de Privacidad y Tratamiento de Datos.</p>
    <b>5. EJERCICIO DE LOS DERECHOS DEL USUARIO.</b>
    <p>Los “Usuarios”, sin perjuicio de lo señalado en el punto N°6 siguiente, podrán ejercer sus derechos, enviando sus solicitudes, reclamos y/o consultas al correo electrónico: datospersonales@cencosud.cl</p>
    <b>6. JURISDICCION - COMPETENCIA</b>
    <p>Las controversias que surjan en relación con lo establecido en esta Política De Privacidad Y Tratamiento De Datos Personales de CENCOSUD SHOPPING S.A., podrán ser sometidas al conocimiento del Juzgado de Policía Local correspondiente al domicilio del consumidor o al domicilio del proveedor, a elección del consumidor; sin perjuicio de la competencia de los tribunales ordinarios de justicia para otro tipo de materias, según lo establecido en la ley 19.496 Sobre Protección de los Derechos de los Consumidores y la ley N°19.628 Sobre Protección de la Vida Privada.</p>
    <b>7. REPRESENTANTE LEGAL</b>
    <p>La calidad de representante legal corresponde a don Ricardo González Novoa, con dirección para estos efectos en Av. Kennedy 9001, piso 4, Las Condes</p>
    <b>II. CONDICIONESPARTICULARES DE LA PLATAFORMA: ontour.cencomallstechpro.com</b>
    <b>1. MEDIDAS DE SEGURIDAD.</b>
    <p>Para cumplir los objetivos de seguridad, la plataforma cuenta con el protocolo de seguridad de hipertexto seguro (HTTPS) el cual utiliza un sistema de encriptación de comunicaciones denominado TLS. Para reconocer cuando una transacción está protegida por un servidor seguro, el Usuario deberá revisar si en su navegador aparece una llave o un candado cerrado en la parte superior en la barra de direcciones, dependiendo del explorador que utilice.</p>
    <p>CencoMalls cuenta con equipos de seguridad informática que permanentemente monitorean la actividad en nuestras páginas web y aplicaciones. En caso de que Cencosud detecte acciones sospechosas de parte de terceros, podrá tomar acciones preventivas y se reserva el derecho de bloquear las cuentas afectadas, en este último caso, nos comunicaremos con los usuarios titulares para explicar la situación y las acciones a seguir, dependiendo de la actividad detectada.</p>
    <b>2. COOKIES</b>
    <p>Una cookie es información almacenada su navegador web, que le permite a puntoscencosud.cl conocer la actividad del Usuario en la plataforma. Sólo se utilizarán para entregar una experiencia personalizada, ayudar al Usuario a navegar de forma más eficiente y entregar información publicitaria relacionada con los intereses del Usuario.</p>
    <p>El Usuario podrá bloquear o eliminar las cookies que utiliza puntoscencosud.cl en la barra de herramientas de su navegador. Sin embargo, debe tener presente que esto podría desactivar algunas funcionalidades de la plataforma, por ejemplo aquellas necesarias para realizar un canje. Respecto de Usuarios no registrados, puntoscencosud.cl no captura información referente a actividades específicas. Sin embargo, se generan reportes que nos permiten ver la actividad consolidada anónima.</p>
    <b>3. GOOGLE ANALYTICS EN PUNTOSCENCOSUD.CL</b>
    <p>Google Analytics es una herramienta para el análisis de datos que, mediante la utilización de cookies desarrolladas por Google, permite obtener información estadística relacionada a los Usuarios que visiten la plataforma puntoscencosud.cl. Adicionalmente, se utiliza para mostrar anuncios publicitarios en otros sitios web asociados a la red de contenido de Google, basándose en las visitas previas del Usuario a la plataforma. Los Usuarios tendrán siempre derecho a deshabilitar las cookies de que se da cuenta en los párrafos anteriores, no pudiendo puntoscencosud.cl evitar de manera alguna dicha acción.</p>
    <b>4. ADVERTENCIA IMPORTANTE</b>
    <p>CencoMalls no efectúa llamados de parte de los Gerentes o personal Cencosud solicitando tus datos personales para confirmar premios. En tal caso, se solicita al Usuario terminar la conversación y contactar mediante correo electrónico indicado en la presente Política de Privacidad y Tratamiento de Datos.</p>`,
  },
  en: {
    title: 'PRIVACY POLICY AND PROCESSING OF PERSONAL DATA “ON TOUR”',
    subtitle: 'CENCOSUD SHOPPING S.A.',
    download: 'Download',
    close: 'Close',
    body: `<h4>Last update, December 23rd, 2024</h4>
    <b>I. GENERAL TERMS</b>
    <b>STATEMENT</b>
    <p>Cencosud Shopping S.A., 76.433.310-1 (hereinafter CencoMalls), domiciled for these purposes at Av. Kennedy 9001, 4th floor, commune of Las Condes, Metropolitan Region, has prepared this Privacy Policy and Processing of Personal Data with the purpose that the Users, registered in the web page https://ontour.cencomalls.com/ (hereinafter the “Platform”), have knowledge about the personal information that is gathered with respect to them, as well as the way in which it is used and the purpose of its treatment. It is hereby stated for the record that any changes made to this policy will be reported in a timely manner on the website https://ontour.cencomalls.com/. An updated text of this policy will be published on this platform.</p>
    <b>2. STORAGE, PURPOSE, CUSTODY AND DATA PROCESSING</b>
    <p>To have the quality of “User”, the form on the website ontour.cencomallstechpro.com must be completed, as indicated in the Terms and Conditions, with the following data:</p>
    <p>• Name and surname</p>
    <p>• Passport or DNI</p>
    <p>• E-mail address</p>
    <p>• Country of residence</p>
    <p>• Date of entry to Chile</p>
    <p>• Gender</p>
    <p>The “User” may visit this platform as a registered “User” and as an unregistered “User”. Access to the services offered is only available as a registered User and once the On Tour Card has been activated. The mere visit to this “platform” does not impose any obligation on the “User”. It is important that the User acknowledges that by joining the Platform, the User declares that the background or personal data provided, hereinafter the “Information”, are substantially correct and correspond to reality, and must communicate any modification or change in these, as soon as they occur, being the “User” personally responsible for the consequences that may cause non-compliance.</p>
    <p>In consideration of the foregoing, the “User” expressly consents that, as an effect of its incorporation into the Platform, the information collected or received from the “Users” through the registration form, may be processed, understanding as such “...... any operation or complex of operations or technical processing, automated or not, to collect, store, record, organize, process, group, select, extract, compare, interconnect, dissociate or cancel the information, ...”. It may also be subject to:</p>
    <p>1. Disclosure: Understanding as such communicating, disclosing, transferring or canceling personal data or using it in any authorized manner.</p>
    <p>2. Processing: Understanding as such the mechanisms and processes that allow providing the “User” with the services offered;</p>
    <p>The Information may be used for the purposes expressed below:</p>
    <p>1. The maintenance of contractual and / or commercial relationships arising from the use of the platform and On Tour Card.</p>
    <p>2. The preparation and/or improvement of the products and/or services offered by CencoMalls and provided to the “User(s)”, as well as the design of new products and/or services for them;</p>
    <p>3. Send any type of information, such as technical background, advertising, offers and promotion of products and/or services, whether offered by CencoMalls, or any other company that is part, or become part of the Cencosud Group; and</p>
    <p>4. To carry out individual and/or collective studies of any kind, such as, for example, demographic, historical, behavioral studies of the “Users” and market research; always taking care of the reserve and confidentiality of the personal data in the manner required by law.</p>
    <p>CencoMalls may dispose and communicate to the rest of the companies that are part, or become part of the Cencosud Group; all or part of the Information provided by the “User”, in which case the recipient must comply with any of the following requirements:</p>
    <p>1. To be a person related to the ownership or management of companies that are part, or will become part of the Cencosud Group;</p>
    <p>2. To be a subsidiary or controlling company of the same, or a legal entity that is under the common control of companies that are part, or will become part, of the Cencosud Group; and/or of the companies with which these establish commercial agreements for the development of strategies related to their line of business and/or</p>
    <p>3. Being a Participating Brand.</p>
    <p>The Platform shall ensure that the recipient of such information treat it under appropriate parameters of confidentiality and that, in the event that the “User” opposes the use of this, it will be promptly deleted from the respective records. Finally, both this platform and the aforementioned recipients may only use the information for the purposes stated above. The Platform shall ensure that the recipient of such information ensures that it will be treated under appropriate parameters of confidentiality and that, in the event that the “User” opposes the use of this, it will be promptly removed from the respective records.</p>
    <b>3. DATA RETENTION PERIOD</b>
    <p>Cencosud Shopping S.A. will keep the User's registration data as long as they are required for the fulfillment of one or more of the purposes specified in Point No. 2 of this Privacy Policy and Personal Data Processing.</p>
    <b>4. “USER” RIGHTS”</b>
    <p>The “User” shall have the right to access the personal data collected, as well as the rights of cancellation, rectification and opposition. The personal data of the “Users” shall be modified when they are erroneous, inaccurate, misleading or incomplete, or shall be deleted when their storage lacks legal basis or have expired, and all other rights conferred by Law No. 19.628 on Protection of Privacy, and the Political Constitution of the Republic. Likewise, the “User” will have the right to revoke the processing authorization, in which case the revocation will produce its effects towards the future. The “User” shall also have the right to request not to receive advertising communications. To do so, the User must send a written communication to the e-mail address mentioned in Point 5 of this Privacy and Data Processing Pol.</p>
    <b>5. EXERCISE OF THE USER'S RIGHTS.</b>
    <p>The “Users”, without prejudice to what is indicated in point N°6 below, may exercise their rights by sending their requests, claims and/or queries to the following e-mail address: datospersonales@cencosud.cl</p>
    <b>6. JURISDICTION - COMPETENCE</b>
    <p>The controversies that arise in relation to the established in this Privacy Policy and Treatment of Personal Data of CENCOSUD SHOPPING S.A., may be submitted to the knowledge of the Local Police Court corresponding to the domicile of the consumer or the domicile of the supplier, at the choice of the consumer; without prejudice to the competence of the ordinary courts of justice for other types of matters, as established in the law No. 19.496 On Protection of the Rights of Consumers and the law No. 19.628 On Protection of the Private Life.</p>
    <b>7. LEGAL REPRESENTATIVE</b>
    <p>The legal representative is Mr. Ricardo González Novoa, with address for these purposes at Av. Kennedy 9001, 4th floor, Las Condes.</p>
    <b>II. PARTICULAR CONDITIONS OF THE PLATFORM: ontour.cencomallstechpro.com</b>
    <b>1. SECURITY MEASURES.</b>
    <p>To meet the security objectives, the platform has the Hypertext Security Protocol Secure (HTTPS) which uses a communications encryption system called TLS. To recognize when a transaction is protected by a secure server, the User should check if a key or a closed padlock appears at the top of the address bar, depending on the browser used.</p>
    <p>CencoMalls has computer security teams that permanently monitor the activity on our web pages and applications. In case Cencosud detects suspicious actions by third parties, may take preventive actions and reserves the right to block the affected accounts, in the latter case, we will communicate with the users to explain the situation and the actions to follow, depending on the activity detected.</p>
    <b>2. COOKIES</b>
    <p>A cookie is information stored in your web browser, which allows puntoscencosud.cl to know the User's activity on the platform. They will only be used to deliver a personalized experience, help the User to navigate more efficiently and deliver advertising information related to the User's interests.</p>
    <p>The User may block or delete cookies used by puntoscencosud.cl in the toolbar of the browser. However, The  User should be aware that this could disable some features of the platform, for example those necessary to make a redemption. Regarding unregistered Users, puntoscencosud.cl does not capture information regarding specific activities. However, reports are generated that allow us to see anonymous consolidated activity.</p>
    <b>3. GOOGLE ANALYTICS IN PUNTOSCENCOSUD.CL</b>
    <p>Google Analytics is a tool for data analysis that, through the use of cookies developed by Google, allows to obtain statistical information related to Users who visit the platform puntoscencosud.cl. Additionally, it is used to display advertisements on other websites associated with the Google content network, based on the User's previous visits to the platform. Users will always have the right to disable cookies that is reported in the preceding paragraphs, puntoscencosud.cl cannot prevent such action in any way.</p>
    <b>4. IMPORTANT NOTICE</b>
    <p>CencoMalls does not make calls on behalf of Cencosud Managers or staff requesting your personal data to confirm prizes. In such cases, Users are advised to terminate the conversation and contact us via the email address provided in this Privacy Policy and Data Processing Statement.</p>`,
  },
  pt: {
    title: 'POLÍTICA DE PRIVACIDADE E TRATAMENTO DE DADOS PESSOAIS “ON TOUR”',
    subtitle: 'CENCOSUD SHOPPING S.A.',
    download: 'Baixar',
    close: 'Fechar',
    body: `<h4>Última atualização em 23 de dezembro de 2024</h4>
    <b>I. TERMOS E CONDIÇÕES GERAIS</b>
    <b>DECLARAÇÃO</b>
    <p>A Cencosud Shopping S.A., 76.433.310-1 (doravante CencoMalls), domiciliada para estes efeitos na Av. Kennedy 9001, 4º andar, município de Las Condes, Região Metropolitana, elaborou a presente Política de Privacidade e Tratamento de Dados Pessoais para que os Usuários, registados no site https://ontour.cencomallstechpro.com/ (doravante “Plataforma”), conheçam os dados pessoais que são recolhidos sobre eles, bem como a forma como são utilizados e a finalidade do seu tratamento. Fica desde já registado que quaisquer alterações introduzidas na presente política serão prontamente publicadas no sítio https://ontour.cencomalls.com/. Um texto atualizado da presente política será publicado nesta plataforma.</p>
    <b>2. ARMAZENAMENTO, FINALIDADE, CUSTÓDIA E TRATAMENTO DOS DADOS</b>
    <p>Para se tornar um “Usuário”, deve preencher o formulário no sítio Web https://ontour.cencomalls.com/, tal como indicado nas Condições Gerais, com os seguintes dados:</p>
    <p>• Nome e apelido</p>
    <p>• Número de passaporte ou bilhete de identidade</p>
    <p>• Endereço de correio eletrônico</p>
    <p>• País de residência</p>
    <p>• Data de entrada no Chile</p>
    <p>• Género</p>
    <p>O “Usuário” pode visitar esta plataforma como “Usuário” registado e como “Usuário” não registado. Só poderá aceder aos serviços oferecidos, como Usuário registado e, uma vez ativado o Cartão On Tour. A simples visita a esta “plataforma” não impõe qualquer obrigação ao “Usuário”. É importante que o “Usuário” seja consciente de que, ao aceitar a sua incorporação na Plataforma, declara que os antecedentes ou dados pessoais facultados, doravante denominados “Informação”, são substancialmente corretos e correspondem à realidade, devendo comunicar qualquer modificação ou alteração deles, logo que ocorram, sendo o “Usuário” pessoalmente responsável pelas consequências que possam derivar do incumprimento.</p>
    <p>Em consideração ao acima exposto, o “Usuário” consente expressamente que, como efeito de sua incorporação à Plataforma, as Informações coletadas ou recebidas dos “Usuários”, por meio do formulário de cadastro, poderão ser objeto de tratamento, entendido como “...... qualquer operação ou complexo de operações ou processamento técnico, automatizado ou não, que permita coletar, armazenar, registrar, organizar, processar, agrupar, selecionar, extrair, comparar, interconectar, dissociar ou cancelar a informação;...”. Ela também pode estar sujeita a:</p>
    <p>1. Disposição: Entendida como a comunicação, atribuição, transferência ou cancelamento de dados pessoais ou seu uso de qualquer forma autorizada.</p>
    <p>2. Tratamento: Entende-se como os mecanismos e processos que permitem que o “Usuário” receba os serviços oferecidos;</p>
    <p>As Informações podem ser usadas para os fins descritos abaixo:</p>
    <p>1. A manutenção das relações contratuais e/ou comerciais decorrentes do uso da plataforma e do Cartão On Tour.</p>
    <p>2. A elaboração e/ou aprimoramento dos produtos e/ou serviços oferecidos pela CencoMalls e prestados ao(s) “Usuário(s)”, bem como a concepção de novos produtos e/ou serviços para os mesmos;</p>
    <p>3. Enviar todo tipo de informação, como antecedentes técnicos, publicidade, ofertas e promoção de produtos e/ou serviços, sejam eles oferecidos pela CencoMalls, ou por qualquer outra empresa que faça parte ou venha a fazer parte do Grupo Cencosud; e</p>
    <p>4. Realizar estudos individuais e/ou coletivos de qualquer natureza, como, por exemplo, estudos demográficos, históricos, comportamento dos “Usuários” e pesquisas de mercado; sempre zelando pela reserva e confidencialidade dos dados pessoais na forma exigida por lei..</p>
    <p>A CencoMalls poderá dispor e comunicar ao resto das empresas que fazem parte, ou que possam vir a fazer parte do Grupo Cencosud, toda ou parte da Informação fornecida pelo “Usuário”, em cujo caso o destinatário deverá cumprir com um dos seguintes requisitos:</p>
    <p>1. Ser uma pessoa relacionada com a propriedade ou administração de empresas que façam parte ou venham a fazer parte do Grupo Cencosud;</p>
    <p>2. Ser uma empresa subsidiária ou controladora dela, ou uma pessoa jurídica que esteja sob o controle comum de empresas que façam parte, ou venham a fazer parte, do Grupo Cencosud; e/ou das empresas com as quais estas estabeleçam acordos comerciais para o desenvolvimento de estratégias vinculadas a seus negócios e/ou</p>
    <p>3. Ser uma Marca Participante.</p>
    <p>A Plataforma assegurará que o destinatário das informações acima mencionadas garanta que elas serão tratadas sob parâmetros adequados de reserva e que, caso o “Usuário” se oponha ao uso dessas informações, elas serão removidas dos respectivos registros. Por fim, tanto esta plataforma quanto os destinatários acima mencionados somente poderão utilizar as informações para os fins descritos acima. A Plataforma deverá assegurar que o destinatário de tais informações garanta que elas serão tratadas sob parâmetros adequados de confidencialidade e que, caso o “Usuário” se oponha ao uso dessas informações, elas serão prontamente removidas dos respectivos registros.</p>
    <b>3. PRAZO DE CONSERVAÇÃO DOS DADOS</b>
    <p>O Cencosud Shopping S.A. manterá os dados de registro do Usuário durante o tempo necessário para o cumprimento de uma ou mais das finalidades estabelecidas no Ponto N°2 desta Política de Privacidade e Tratamento de Dados Pessoais.</p>
    <b>4. DIREITOS DO “USUÁRIO"</b>
    <p>O “Usuário” terá o direito de acesso aos dados pessoais coletados, bem como os direitos de cancelamento, retificação e oposição. Os dados pessoais dos “Usuários” serão modificados quando estiverem errados, imprecisos, enganosos ou incompletos, ou serão excluídos quando seu armazenamento não tiver base legal ou tiver expirado, e todos os outros direitos conferidos pela Lei nº 19.628 sobre a Proteção da Vida Privada e pela Constituição Política da República. Da mesma forma, o “Usuário” terá o direito de revogar a autorização de processamento, caso em que a revogação terá efeito no futuro. O “Usuário” também terá o direito de solicitar o não recebimento de comunicações publicitárias. Para isso, o Usuário deverá enviar uma comunicação por escrito para o endereço de e-mail mencionado no Ponto 5 desta Política de Privacidade e Processamento de Dados.</p>
    <b>5. EXERCÍCIO DOS DIREITOS DO USUÁRIO.</b>
    <p>Os “Usuários”, sem prejuízo do indicado no ponto N°6 abaixo, poderão exercer seus direitos enviando suas solicitações, reclamações e/ou consultas para o seguinte endereço de e-mail: datospersonales@cencosud.cl</p>
    <b>6. JURISDIÇÃO - COMPETÊNCIA</b>
    <p>As controvérsias que surgirem em relação ao estabelecido nesta Política de Privacidade e Tratamento de Dados Pessoais do CENCOSUD SHOPPING S.A., poderão ser submetidas ao conhecimento do Tribunal de Polícia Local correspondente ao domicílio do consumidor ou ao domicílio do fornecedor, à escolha do consumidor; sem prejuízo da competência dos tribunais de justiça ordinários para outros tipos de assuntos, conforme estabelecido na lei 19.496 sobre Proteção dos Direitos dos Consumidores e na lei n°19.628 sobre Proteção da Vida Privada.</p>
    <b>7. REPRESENTANTE LEGAL</b>
    <p>O representante legal é o Sr. Ricardo González Novoa, com endereço para esses fins na Av. Kennedy 9001, 4º andar, Las Condes.</p>
    <b>II. CONDIÇÕES PARTICULARES DA PLATAFORMA: ontour.cencomallstechpro.com</b>
    <b>1. MEDIDAS DE SEGURANÇA.</b>
    <p>Para atender aos objetivos de segurança, a plataforma conta com o Protocolo de segurança de hipertexto seguro (HTTPS), que utiliza um sistema de criptografia de comunicação chamado TLS. Para reconhecer quando uma transação está protegida por um servidor seguro, o Usuário deve verificar se aparece uma chave ou um cadeado fechado na parte superior da barra de endereços do seu navegador, dependendo do navegador que utiliza.</p>
    <p>A CencosMalls possui equipes de segurança de computadores que monitoram constantemente a atividade em nossos sites e aplicativos. Caso a Cencosud detecte ações suspeitas por parte de terceiros, poderá tomar medidas preventivas e se reserva o direito de bloquear as contas afetadas. Neste último caso, nos comunicaremos com os usuários para explicar a situação e as ações a serem tomadas, dependendo da atividade detectada.</p>
    <b>2. COOKIES</b>
    <p>Um cookie é uma informação armazenada em seu navegador da Web, que permite que a puntoscencosud.cl conheça a atividade do usuário na plataforma. São utilizados apenas para proporcionar uma experiência personalizada, ajudar o usuário a navegar de forma mais eficiente e fornecer informações publicitárias relacionadas aos interesses do usuário.</p>
    <p>O Usuário pode bloquear ou excluir os cookies utilizados pelo puntoscencosud.cl na barra de ferramentas do seu navegador. No entanto, deve estar ciente de que isso pode desativar alguns recursos da plataforma, por exemplo, aqueles necessários para fazer um resgate. Em relação aos usuários não registrados, o puntoscencosud.cl não captura informações sobre atividades específicas. No entanto, são gerados relatórios que nos permitem ver a atividade consolidada anônima.</p>
    <b>3. GOOGLE ANALYTICS EM PUNTOSCENCOSUD.CL</b>
    <p>O Google Analytics é uma ferramenta de análise de dados que, através do uso de cookies desenvolvidos pelo Google, nos permite obter informações estatísticas relacionadas aos usuários que visitam a plataforma puntoscencosud.cl. Além disso, é usado para exibir anúncios em outros sites associados à rede de conteúdo do Google, com base nas visitas anteriores do usuário à plataforma. O Usuário sempre terá o direito de desativar os cookies a que se refere os parágrafos anteriores, não podendo o puntoscencosud.cl, de forma alguma, impedir tal ação.</p>
    <b>4. AVISO IMPORTANTE</b>
    <p>A CencoMalls não realiza ligações de Gerentes ou funcionários da Cencosud solicitando seus dados pessoais para confirmação de prêmios. Nesse caso, solicita-se ao Usuário que encerre a conversa e entre em contato por meio do endereço de e-mail indicado nesta Política de Privacidade e Tratamento de Dados.</p>`,
  },
};

export default politica;