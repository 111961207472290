const tyc = {
  es: {
    mayorButton: 'Volver',
    mayorTitle: 'Información Legal',
    title: 'TÉRMINOS Y CONDICIONES GENERALES',
    subtitle: 'TARJETA DE DESCUENTO “ON TOUR”',
    download: 'Descargar',
    close: 'Cerrar',
    body: `<h4>Ultima actualización, 23 de diciembre de 2024</h4>
          <b>I. Disposiciones generales</b>
          <b>1. Antecedentes</b>
          <p>Cencosud Shopping S.A., RUT 76.433.310-1, en adelante "CencoMalls", con domicilio en Av. Kennedy N° 9001, piso 6°, comuna de Las Condes, Región Metropolitana, ha desarrollado estos Términos y Condiciones con el propósito de regular la relación con los consumidores (en adelante, "Usuarios") en relación con el acceso y uso de la tarjeta de descuento denominada “On Tour” (en adelante, indistintamente “On Tour” o “Tarjeta On Tour”). Las condiciones asociadas a esta tarjeta están disponibles en https://ontour.cencomalls.com/o a través de la Plataforma.</p>
          <p>Para acceder a los beneficios de On Tour, el Usuario deberá registrarse en la Plataforma.</p>
          <p>Los beneficios ofrecidos son otorgados exclusivamente por las distintas Marcas Participantes, las cuales, junto con los beneficios correspondientes, están detallados en https://cencomalls.cl/costanera/Ontour.</p>
          <p>La Plataforma desempeña dos roles distintos e independientes:</p>
          <p><b>a) Proveedor en Plataforma Propia:</b> En su calidad de proveedor, CencoMalls, se encarga de gestionar las solicitudes para la tarjeta de descuento “On Tour”. Los actos y contratos relacionados con estas solicitudes se regirán por los presentes Términos y Condiciones. La calidad de proveedor corresponde a Cencosud Shopping S.A., RUT N°76.433.310-1, domiciliado en Av. Kennedy N° 9001, piso 6°, comuna de Las Condes.</p>
          <p><b>b) Operador de la Plataforma:</b> CencoMalls, actúa como operador de la Plataforma en lo relacionado con la información y utilización de los beneficios ofrecidos por las Marcas Participantes. Asimismo, esta función incluye los productos y/o servicios comercializados por dichas marcas, las cuales son entidades jurídicas y/o empresas independientes de Cencosud Shopping S.A. En estos casos, las visitas, contratos y transacciones que el Usuario realice estarán regidos por los Términos y Condiciones propios de cada Marca Participante, disponibles en sus respectivos sitios web y/o aplicaciones.</p>
          <b>2. Registro del usuario y Requisitos utilizar On Tour</b>
          <p>Podrán obtener la tarjeta de descuento On Tour, todos aquellos que cumplan con los siguientes requisitos:</p>
          <p>A. Ser extranjero, sin residencia definitiva en Chile;</p>
          <p>B. Ser persona natural, mayor de 18 años. No podrán utilizarlo las personas menores de 18 años, ni los Usuarios cuyas Cuentas de usuario hayan sido suspendidas temporalmente o inhabilitados deﬁnitivamente;</p>
          <p>C. Haber cumplido con el proceso de registro;</p>
          <p>D. Haber leído y aceptado inequívocamente los Términos y Condiciones, y Política de Privacidad de On Tour.</p>
          <p>Para solicitar la tarjeta On Tour, el Usuario deberá:</p>
          <p>1. Registrarse en la Plataforma completando el formulario correspondiente con datos válidos, exactos, precisos y veraces. Este requisito es fundamental para la aprobación de la solicitud de registro. CencoMalls podrá corroborar Datos Personales, y suspender temporal o definitivamente a aquellos Usuarios, cuyos datos no hayan podido ser confirmados y/o que resulten no ser verdaderos.</p>
          <p>2. Leer y aceptar los Términos y Condiciones y la Política de Privacidad y De Tratamiento de Datos de On Tour. La aceptación inequívoca de los Términos y Condiciones y Políticas de Privacidad precedentemente singularizadas, el Usuario no podrá avanzar en el proceso de registro.</p>
          <p>Una vez que el Usuario se haya registrado y haya recibido la tarjeta On Tour, podrá utilizarla para realizar compras, solicitar servicios y acceder a información relevante. Cabe destacar que la venta, cesión o transferencia de la tarjeta de descuento On Tour está estrictamente prohibida. Esta tarjeta es digital, nominativa y personalísima.</p>
          <p>La solicitud permite al Usuario efectuar compras, solicitar servicios y obtener información. Se aclara que está prohibida la venta, cesión o transferencia de la tarjeta de descuento On Tour.</p>
          <b>2.1. Formulario de inscripción, validación y confirmación de registro.</b>
          <p>Una vez completado el formulario de inscripción, el Usuario recibirá un correo electrónico con los pasos a seguir para activar On Tour, los que serán:</p>
          <p>• Acudir de manera presencial al centro comercial Cenco Costanera, dirigirse al módulo “Informaciones” ubicado en el primer nivel.</p>
          <p>• Presentar los siguientes documentos para verificar su identidad:</p>
          <p> - Pasaporte o DNI utilizado en el formulario de registro.</p>
          <p> - Correo electrónico enviado por On Tour, confirmando su registro.</p>
          <p>Cumplido lo anterior, el usuario recibirá su tarjera On Tour en formato digital en el correo electrónico registrado en el formulario de inscripción.</p>
          <p>La activación de On Tour, quedará sujeta a validación del cumplimiento del proceso señalado precedentemente. CencoMalls, podrá rechazar la solicitud, si no se cumplen las condiciones señaladas.</p>
          <b>3. Vigencia On Tour</b>
          <p>3.1	La tarjeta On Tour, tendrá una vigencia de 15 días corridos, contados desde su activación.</p>
          <p>3.2	El Usuario podrá solicitar una nueva tarjeta una vez transcurridos 3 meses desde la fecha de vencimiento de la Tarjeta On Tour anterior.</p>
          <b>4. Adaptaciones para facilitar la revisión y comprensión de nuestros Términos y Condiciones.</b>
          <p>Estos Términos y Condiciones han sido adaptados en la plataforma, mediante la incorporación de funcionalidades especiales que contribuyan a facilitar la comprensión a todos los Usuarios; de acuerdo con las normas de la ley 19.496 Sobre Protección de los Derechos de los Consumidores.</p>
          <p>Estas funcionalidades especiales, consisten en que, mediante su utilización, los Usuarios podrán:</p>
          <p>A.	Controlar el tamaño de la letra y escoger el que más se acomode a su visión, utilizando los controles habilitados y de fácil acceso en la Plataforma.</p>
          <p>B.	Controlar, a su voluntad, el contraste entre la letra y el color de fondo, de modo de facilitar la legibilidad de los textos.</p>
          <p>C. Utilizar lectores de pantalla y elementos de navegación del teclado que transforman el texto en un audio. Para utilizar esta funcionalidad, el Usuario debe instalar un Programa lector de pantalla.</p>
          <b>5. Efecto vinculante de los Términos y Condiciones</b>
          <p>Una vez aceptados los Términos y Condiciones, estos serán obligatorios y vinculantes. Cada vez que se realice una actualización del presente documento o que el Usuario solicite nuevamente una Tarjeta On tour, deberá expresar nuevamente su aceptación a los mismos en https://ontour.cencomallstechpro.com/.</p>
          <b>6. Jurisdicción.</b>
          <p>Las controversias que surjan en relación con lo establecido en estos Términos y Condiciones podrán interponerse, a elección del consumidor, ante el Juzgado de Policía Local correspondiente a su domicilio o al domicilio del proveedor. Sin perjuicio de la competencia de los tribunales ordinarios de justicia para otro tipo de materias. Todo lo anterior, según el artículo 50 A de la ley 19.496.</p>
          <b>7. Representante legal.</b>
          <p>Para todos los efectos legales y relacionados con la presente información, Cencosud Shopping S.A designa como representante legal de la empresa al señor Ricardo González Novoa, Cédula de Identidad N°14.292.860-4, domiciliado en Av. Presidente Kennedy 9001, Piso 6, Las Condes.</p>
          <b>II. Disposiciones específicas</b>
          <b>1. Proceso de compra y descuento en las Marcas Participantes</b>
          <p>Se entenderá como tal a las marcas informadas en https://cencomalls.cl/costanera/Ontour, las cuales patrocinan y otorgan los beneficios disponibles para los Usuarios.</p>
          <p>Cencosud Shopping S.A. ofrece a los Usuarios descuentos aplicables en locales comerciales adheridos, ubicados en el Centro Comercial Cenco Costanera. Estos descuentos son válidos para la adquisición de productos y servicios ofrecidos por dichos locales, bajo las condiciones establecidas por cada descuento.</p>
          <p>Para hacer efectivo el descuento, el Usuario deberá presentar una copia del correo electrónico correspondiente a la Marca Participante.</p>
          <b>2. Derechos de los usuarios en On Tour</b>
          <p>La Marca Participante es responsable de gestionar todas y cada una de las peticiones de los Usuarios On Tour, derivadas de las solicitudes de compra en cada local de las Marcas, especialmente, las que sean consecuencia del ejercicio de cualquiera de los siguientes derechos que la Marca Participante debe reconocer y respetar al Usuario.</p>
          <p>• Derecho a Devolución: Conforme a los respectivos Términos y Condiciones, informados en la página web.</p>
          <p>• Garantía Legal: Si el Usuario quiere hacer uso del derecho a la garantía legal que le reconoce la ley 19.496 sobre Protección de los Derechos de los Consumidores, deberá ponerse en contacto directo con la tienda o la Marca Participante, a su elección, quien realizará las gestiones que procedan. Para ejercer este derecho, el consumidor deberá acreditar el acto o contrato con la respectiva documentación, como la boleta, factura, o comprobante de pago.</p>
          <p>• Contacto con la Marca Participante: Si el Usuario solicita los datos de contacto de la Marca Participante, Cencosud Shopping S.A. entregará la información necesaria para que el Usuario pueda ejercer sus derechos, especialmente, los derechos a la información y reclamación.</p>
          <b>3. Obligaciones de los usuarios</b>
          <p>Antes de solicitar la compra de un producto o beneficio, el Usuario debe leer y revisar cuidadosa, detallada y responsablemente la información publicada por la Marca Participante sobre la identificación del bien o producto, los instructivos de uso, los términos de las garantías, su precio, condiciones de contratación y otras características relevantes, informados en la página web https://ontour.cencomallstechpro.com</p>
          <b>4. Ofertas y Promociones</b>
          <p>CencoMalls, las Marcas Participantes y/o Empresas externas podrán ofrecer a los Usuarios de On tour ofertas, promociones, concursos por medio de cupones promocionales u otros medios ad-hoc para los cuales el Usuario deberá aceptar adicionalmente las bases de promoción o similar del oferente de la promoción respectiva que serán oportunamente informadas.</p>
          <p>Todas las bases Promocionales, de concurso, o similares, estarán debidamente publicadas en el sitio web de On Tour y por el oferente de la Promoción quién es el exclusivo responsable de su cumplimiento.</p>
          <b>5. Sanciones. Suspensión de operaciones</b>
          <p>Sin perjuicio de otras medidas, CencoMalls podrá advertir, suspender en forma temporal o inhabilitar deﬁnitivamente a un Usuario o una publicación, si el titular de aquella incurriera en conductas contrarias a la Ley, la moral, el orden público y/o las buenas costumbres.</p>
          <b>6. Propiedad intelectual. Enlaces</b>
          <p>Todo contenido relativo a los servicios de On tour como así también las bases de datos, redes, archivos, entre otros, que permiten al Usuario acceder y registrarse, son de propiedad de Cencosud Shopping S.A. y están protegidas por las leyes y los tratados internacionales de derecho de autor, marcas, patentes, modelos y diseños industriales. El uso indebido y la reproducción total o parcial de dichos contenidos quedan prohibidos, salvo autorización expresa y por escrito de Cencosud Shopping S.A.</p>
          <b>7. Modificaciones de los términos y condiciones</b>
          <p>CencoMalls podrá modificar los Términos y Condiciones en cualquier momento informando la actualización de estos en la Plataforma.</p>
          <p>8. Centro de Ayuda: Contacto</p>
          <p>Para presentar solicitudes, y/o reclamos referentes a acciones ejecutadas o celebradas a través de la Plataforma, el Usuario puede contactarse vía correo electrónico a ontour@cencomalls.com.</p>`,
  },
  en: {
    mayorButton: 'Back',
    mayorTitle: 'Legal Information',
    title: 'GENERAL TERMS AND CONDITIONS',
    subtitle: 'ON TOUR DISCOUNT CARD',
    download: 'Download',
    close: 'Close',
    body: `<h4>Last update, December 23rd, 2024</h4>
          <b>I. General Provisions</b>
          <b>1. Background</b>
          <p>Cencosud Shopping S.A., RUT 76.433.310-1, hereinafter "CencoMalls", with address at Av. Kennedy N° 9001, 6th floor, commune of Las Condes, Metropolitan Region, has developed these Terms and Conditions with the purpose of regulating the relationship with consumers (hereinafter, "Users") in relation to the access and use of the discount card called "On Tour" (hereinafter, indistinctly "On Tour" or "On Tour Card"). The conditions associated with this card are available at https://ontour.cencomalls.com/ or through the Platform.</p>
          <p>To access the benefits of On Tour, the User must register on the Platform.</p>
          <p>The benefits offered are granted exclusively by the different Participating Brands, which, together with the corresponding benefits, are detailed in https://cencomalls.cl/costanera/Ontour.</p>
          <p>The Platform plays two distinct and independent roles:</p>
          <p><b>a) Supplier on own platform:</b> As a supplier, CencoMalls is in charge of managing the applications for the "On Tour" discount card. The acts and contracts related to these applications shall be governed by these Terms and Conditions. The quality of supplier corresponds to Cencosud Shopping S.A., RUT 76.433.310-1, domiciled at Av. Kennedy N° 9001, 6th floor, commune of Las Condes.</p>
          <p><b>b) Platform Operator:</b> CencoMalls, acts as operator of the Platform in relation to the information and use of the benefits offered by the Participating Brands. Also, this function includes the products and / or services marketed by such brands, which are legal entities and / or companies independent of Cencosud Shopping S.A. In these cases, visits, contracts and transactions that the User performs will be governed by the Terms and Conditions of each Participating Brand, available on their respective websites and / or applications.</p>
          <b>2. User Registration and Requirements to use On Tour</b>
          <p>The On Tour discount card is available to all those who meet the following requirements:</p>
          <p>A. Be a foreigner, without definitive residence in Chile;</p>
          <p>B. Be a natural person, over 18 years of age. It may not be used by persons under 18 years of age, or by Users whose User Accounts have been temporarily suspended or defined;</p>
          <p>C. To have complied with the registration process;</p>
          <p>D. Have read and unequivocally accepted On Tour's Terms and Conditions and Privacy Policy.</p>
          <p>To apply for the On Tour card, the User must:</p>
          <p>1. Register on the Platform by completing the corresponding form with valid, accurate, precise and truthful data. This requirement is essential for the approval of the registration request. CencoMalls may corroborate Personal Data, and temporarily or  permanently suspend those Users, whose data could not be confirmed and / or that turn out not to be true.</p>
          <p>2. Read and accept the Terms and Conditions and the Privacy Policy and Data Processing of On Tour. The unequivocal acceptance of the Terms and Conditions and Privacy Policy previously singled out, the User will not be able to move forward in the registration process.</p>
          <p>Once the User has registered and received the On Tour card, he/she may use it to make purchases, request services and access relevant information. It should be noted that the sale, assignment or transfer of the On Tour discount card is strictly prohibited. This card is digital, nominative and very personal.</p>
          <p>The application allows the User to make purchases, request services and obtain information. It is clarified that the sale, assignment or transfer of the On Tour discount card is forbidden.</p>
          <b>2.1. Registration form, validation and confirmation of registration.</b>
          <p>Once the registration form is completed, the User will receive an email with the steps to follow to activate On Tour, which will be:</p>
          <p>• Go in person to the Cenco Costanera shopping center, go to the "Information" module located on the first level.</p>
          <p>• Present the following documents to verify your identity:</p>
          <p> - Passport or ID used in the registration form.</p>
          <p> - E-mail sent by On Tour, confirming your registration.</p>
          <p>Once the above is completed, the user will receive his/her On Tour card in digital format at the e-mail address registered in the registration form.</p>
          <p>The activation of On Tour, will be subject to validation of compliance with the process outlined above. CencoMalls may reject the request if the above conditions are not met.</p>
          <p>3. On Tour Validity</p>
          <p>3.1 The On Tour card shall be valid for 15 calendar days from its activation.</p>
          <p>3.2 The User may apply for a new card after 3 months from the expiration date of the previous On Tour Card.</p>
          <b>4. Adaptations to facilitate the review and understanding of our Terms and Conditions.</b>
          <p>These Terms and Conditions have been adapted on the platform, by incorporating special functionalities that contribute to facilitate the understanding of all Users; in accordance with the rules of Law 19.496 on Consumer Rights Protection.</p>
          <p>These special functionalities consist in the fact that, by means of their use, Users will be able to:</p>
          <p>A.	Control the font size and choose the size that best suits your vision, using the controls enabled and easily accessible on the Platform.</p>
          <p>B.	Control, at will, the contrast between the font and the background color, in order to facilitate the legibility of the texts.</p>
          <p>C. Use screen readers and keyboard navigation elements that transform text into audio. To use this functionality, the User must install a screen reader program.</p>
          <b>5. Binding effect of the Terms and Conditions</b>
          <p>Once the Terms and Conditions have been accepted, they shall be mandatory and binding. Each time this document is updated or the User re-applies for an On tour Card, the User must re-express his/her acceptance of these Terms and Conditions at https://ontour.cencomalls.com/.</p>
          <b>6. Jurisdiction.</b>
          <p>Disputes arising in connection with the provisions of these Terms and Conditions may be brought, at the option of the consumer, before the Local Police Court corresponding to his domicile or the domicile of the supplier. Without prejudice to the competence of the ordinary courts of justice for other types of matters. All the above, according to Article 50 A of Law 19.496.</p>
          <b>7. Legal representative.</b>
          <p>For all legal purposes related to this information, Cencosud Shopping S.A. appoints Mr. Ricardo González Novoa, Identity Card No. 14.292.860-4, domiciled at Av. Presidente Kennedy 9001, Piso 6, Las Condes, as legal representative of the company.</p>
          <b>II. Specific Provisions</b>
          <b>1. Purchase process and discount at Participating Brands</b>
          <p>It shall be understood as such the brands reported in https://cencomalls.cl/costanera/Ontour, which sponsor and grant the benefits available to Users.</p>
          <p>Cencosud Shopping S.A. offers the Users discounts applicable in adhered commercial premises, located in the Cenco Costanera Shopping Center. These discounts are valid for the purchase of products and services offered by such stores, under the conditions established for each discount.</p>
          <p>To make the discount effective, the User must present a copy of the email corresponding to the Participating Brand.</p>
          <b>2. User rights in On Tour</b>
          <p>The Participating Brand is responsible for managing each and every one of the requests of the On Tour Users, derived from the purchase requests in each Brand location, especially, those that are a consequence of the exercise of any of the following rights that the Participating Brand must recognize and respect to the User.</p>
          <p>• Right of Return: According to the respective Terms and Conditions, informed in the web page.</p>
          <p>• Legal Guarantee: If the User wants to make use of the right to the legal guarantee recognized by Law 19.496 on Consumer Rights Protection, he/she must contact directly the store or the Participating Brand, as he/she chooses, who will take the appropriate steps. To exercise this right, the consumer must prove the act or contract with the respective documentation, such as the receipt, invoice or proof of payment.</p>
          <p>• Contact with the Participating Brand: If the User requests the contact details of the Participating Brand, Cencosud Shopping S.A. will provide the necessary information so that the User may exercise his/her rights, especially the rights to information and complaints.</p>
          <b>3. Obligations of users</b>
          <p>Before requesting the purchase of a product or benefit, the User must carefully, thoroughly and responsibly read and review the information published by the Participating Brand on the identification of the good or product, the instructions for use, the terms of the guarantees, its price, contracting conditions and other relevant characteristics, informed on the web page https://ontour.cencomalls.com/.</p>
          <b>4. Offers and Promotions</b>
          <p>CencoMalls, the Participating Brands and/or external Companies may offer On tour Users offers, promotions, contests by means of promotional coupons or other ad-hoc means for which the User must additionally accept the promotional or similar bases of the respective promotion's offeror, which will be duly informed.</p>
          <p>All promotional, contest or similar rules will be duly published on the On Tour website and by the offeror of the Promotion, who is exclusively responsible for its compliance.</p>
          <b>5. Sanctions. Suspension of operations</b>
          <p>Without prejudice to other measures, CencoMalls may warn, temporarily suspend or disable definitely a User or a publication, if the owner of the publication engages in conduct contrary to the Law, morality, public order and/or good customs.</p>
          <b>6. Intellectual Property. Links</b>
          <p>All content related to the services of On tour as well as the databases, networks, files, among others, that allow the User to access and register, are the property of Cencosud Shopping S.A. and are protected by the laws and international treaties of copyright, trademarks, patents, models and industrial designs. The improper use and the total or partial reproduction of such contents are prohibited, unless expressly authorized in writing by Cencosud Shopping S.A</p>
          <b>7. Modifications to the terms and conditions</b>
          <p>CencoMalls may modify the Terms and Conditions at any time by posting updated Terms and Conditions on the Platform.</p>
          <b>8. Help Center: Contact</b>
          <p>To submit requests and/or complaints regarding actions performed or concluded through the Platform, the User may contact ontour@cencomalls.com by e-mail.</p>`,
  },
  pt: {
    mayorButton: 'Voltar',
    mayorTitle: 'Informações Legais',
    title: 'TERMOS E CONDIÇÕES GERAIS',
    subtitle: 'CARTÃO DE DESCONTO EM TURNÊ',
    download: 'Baixar',
    close: 'Fechar',
    body: `<h4>Última atualização, 23 de dezembro de 2024</h4>
          <b>I. Disposições gerais</b>
          <b>1. Histórico</b>
          <p>A Cencosud Shopping S.A., RUT 76.433.310-1, doravante denominada "CencoMalls", com endereço na Av. Kennedy N° 9001, 6° andar, município de Las Condes, Região Metropolitana, elaborou os presentes Termos e Condições com o objetivo de regular a relação com os consumidores (doravante denominados "Usuários") em relação ao acesso e utilização do cartão de descontos denominado "On Tour" (doravante, indistintamente "On Tour" ou "Cartão On Tour"). Os termos e condições associados a esse cartão estão disponíveis em https://ontour.cencomallstechpro.com/ ou por meio da Plataforma.</p>
          <p>Para acessar os benefícios do On Tour, o Usuário deve se registrar na Plataforma.</p>
          <p>Os benefícios oferecidos são concedidos exclusivamente pelas diversas Marcas Participantes, que, juntamente com os benefícios correspondentes, estão detalhados em https://cencomalls.cl/costanera/Ontour.</p>
          <p>A plataforma desempenha duas funções distintas e independentes:</p>
          <p><b>a) Fornecedor em sua própria plataforma</b>: Na qualidade de fornecedor, a CencoMalls é responsável por gerenciar os pedidos do cartão de desconto "On Tour". Os atos e contratos relacionados a esses aplicativos serão regidos por estes Termos e Condições. A qualidade de fornecedor corresponde à Cencosud Shopping S.A., RUT 76.433.310-1, com domicílio na Av. Kennedy N° 9001, 6º andar, município de Las Condes.</p>
          <p><b>b) Operador da Plataforma:</b> A CencoMalls, atua como operadora da Plataforma em relação à informação e utilização dos benefícios oferecidos pelas Marcas Participantes. Da mesma forma, esta função inclui os produtos e/ou serviços comercializados pelas referidas marcas, que são pessoas jurídicas e/ou empresas independentes do Cencosud Shopping S.A. Nestes casos, as visitas, contratos e transações que o Usuário realizar serão regidos pelos Termos e Condições de cada Marca Participante, disponíveis em seus respectivos sites e/ou aplicativos.</p>
          <b>2. Registro de usuário e requisitos para usar o On Tour</b>
          <p>O cartão de desconto On Tour está disponível para todos aqueles que atendem aos seguintes requisitos:</p>
          <p>A. Ser estrangeiro, sem residência definitiva no Chile;</p>
          <p>B. Ser uma pessoa física, maior de 18 anos de idade. Ele não pode ser usado por pessoas com menos de 18 anos de idade, nem por Usuários cujas Contas de Usuário tenham sido temporariamente suspensas ou canceladas;</p>
          <p>C. Ter concluído o processo de registro;</p>
          <p>D. Ter lido e aceito de forma inequívoca os Termos e Condições e a Política de Privacidade da On Tour.</p>
          <p>Para solicitar o cartão On Tour, o usuário deve:</p>
          <p>1. Registre-se na Plataforma preenchendo o formulário correspondente com dados válidos, exatos, precisos e verdadeiros. Este requisito é essencial para a aprovação do pedido de registro. A CencoMalls poderá corroborar os Dados Pessoais e suspender temporária ou permanentemente os Usuários cujos dados não puderem ser confirmados e/ou que se revelarem inverídicos.</p>
          <p>2. Ler e aceitar os Termos e Condições e a Política de Privacidade e Processamento de Dados da On Tour. A aceitação inequívoca dos Termos e Condições e da Política de Privacidade acima, o Usuário não poderá prosseguir com o processo de cadastramento.</p>
          <p>Uma vez que o usuário tenha se registrado e recebido o cartão On Tour, ele poderá usá-lo para fazer compras, solicitar serviços e acessar informações relevantes. Vale ressaltar que a venda, cessão ou transferência do cartão de desconto On Tour é estritamente proibida. Esse cartão é digital, nominativo e muito pessoal.</p>
          <p>O aplicativo permite que o Usuário faça compras, solicite serviços e obtenha informações. Fica esclarecido que é proibida a venda, cessão ou transferência do cartão de desconto On Tour.</p>
          <b>2.1. Formulário de registro, validação e confirmação do registro.</b>
          <p>Após o preenchimento do formulário de registro, o Usuário receberá um e-mail com as etapas a serem seguidas para ativar o On Tour, que serão:</p>
          <p>• Vá pessoalmente ao shopping center Cenco Costanera, dirija-se ao módulo "Informações" localizado no primeiro nível.</p>
          <p>• Apresente os seguintes documentos para verificar sua identidade:</p>
          <p> - Passaporte ou carteira de identidade usada no formulário de registro.</p>
          <p> - E-mail enviado pela On Tour, confirmando sua inscrição.</p>
          <p>Uma vez feito isso, o usuário receberá seu cartão On Tour em formato digital no endereço de e-mail registrado no formulário de inscrição.</p>
          <p>A ativação do On Tour estará sujeita à validação da conformidade com o processo descrito acima. A CencoMalls poderá rejeitar a solicitação se as condições acima não forem atendidas.</p>
          <b>3. Validade em turnê</b>
          <p>3.1 O cartão On Tour será válido por 15 dias corridos a partir de sua ativação.</p>
          <p>3.2 O usuário pode solicitar um novo cartão 3 meses após a data de vencimento do cartão On Tour anterior.</p>
          <b>4. Adaptações para facilitar a revisão e o entendimento de nossos Termos e Condições.</b>
          <p>Estes Termos e Condições foram adaptados na plataforma, por meio da incorporação de funcionalidades especiais que contribuem para facilitar a compreensão de todos os Usuários; de acordo com as regras da lei 19.496 sobre a Proteção dos Direitos do Consumidor.</p>
          <p>Essas funcionalidades especiais consistem no fato de que, por meio de seu uso, os Usuários poderão:</p>
          <p>A.	Controle o tamanho da fonte e escolha o tamanho que melhor se adapta à sua visão, usando os controles habilitados e facilmente acessíveis na Plataforma.</p>
          <p>B.	Controle, à vontade, o contraste entre a fonte e a cor de fundo, para facilitar a legibilidade dos textos.</p>
          <p>C. Use leitores de tela e elementos de navegação do teclado que transformam texto em áudio. Para usar essa funcionalidade, o usuário deve instalar um programa leitor de tela.</p>
          <b>5. Efeito vinculante dos Termos e Condições</b>
          <p>Uma vez que os Termos e Condições tenham sido aceitos, eles serão vinculativos e obrigatórios. Toda vez que este documento for atualizado ou o Usuário solicitar novamente um Cartão On tour, o Usuário deverá expressar novamente sua aceitação destes Termos e Condições em https://ontour.cencomalls.com/.</p>
          <b>6. Jurisdição.</b>
          <p>As controvérsias que surgirem em relação às disposições destes Termos e Condições poderão ser apresentadas, a critério do consumidor, perante o Tribunal de Polícia Local correspondente ao seu domicílio ou ao domicílio do fornecedor. Sem prejuízo da competência dos tribunais de justiça comuns para outros tipos de questões. Todos os itens acima, de acordo com o Artigo 50 A da Lei 19.496.</p>
          <b>7. Representante legal.</b>
          <p>Para todos os fins legais relacionados a essas informações, a Cencosud Shopping S.A. nomeia o Sr. Ricardo González Novoa, Carteira de Identidade nº 14.292.860-4, domiciliado na Av. Presidente Kennedy 9001, Piso 6, Las Condes, como representante legal da empresa.</p>
          <b>II. Disposições específicas</b>
          <b>1. Processo de compra e desconto nas marcas participantes </b>
          <p>Entende-se como as marcas informadas no site https://cencomalls.cl/costanera/Ontour, que patrocinam e concedem os benefícios disponíveis aos Usuários.</p>
          <p>O Cencosud Shopping S.A. oferece aos Usuários descontos aplicáveis nos estabelecimentos comerciais afiliados localizados no Centro Comercial Cenco Costanera. Esses descontos são válidos para a compra de produtos e serviços oferecidos por essas lojas, de acordo com as condições estabelecidas para cada desconto.</p>
          <p>Para solicitar o desconto, o Usuário deve apresentar uma cópia do endereço de e-mail da Marca Participante.</p>
          <b>2. Direitos do usuário no On Tour</b>
          <p>A Marca Participante é responsável por gerenciar todas e cada uma das solicitações dos Usuários On Tour, derivadas das solicitações de compra nas instalações de cada Marca, especialmente aquelas que são consequência do exercício de qualquer um dos seguintes direitos que a Marca Participante deve reconhecer e respeitar para o Usuário.</p>
          <p>• Direito de devolução: de acordo com os respectivos Termos e Condições, conforme declarado no site.</p>
          <p>• Garantia legal: Se o Usuário desejar fazer uso do direito à garantia legal reconhecida pela Lei 19.496 sobre a Proteção dos Direitos do Consumidor, ele deverá entrar em contato diretamente com a loja ou com a Marca Participante, conforme sua escolha, que tomará as medidas apropriadas. Para exercer esse direito, o consumidor deve comprovar o ato ou contrato com a respectiva documentação, como recibo, fatura ou comprovante de pagamento.</p>
          <p>• Contato com a Marca Participante: Caso o Usuário solicite os dados de contato da Marca Participante, a Cencosud Shopping S.A. fornecerá as informações necessárias para que o Usuário possa exercer seus direitos, especialmente os direitos de informação e reclamação.</p>
          <b>3. Obrigações dos usuários</b>
          <p>Antes de solicitar a compra de um produto ou benefício, o Usuário deverá ler e analisar com atenção, profundidade e responsabilidade as informações publicadas pela Marca Participante sobre a identificação do bem ou produto, as instruções de uso, os termos das garantias, seu preço, condições de contratação e demais características relevantes, informadas no site https://ontour.cencomalls.com/.</p>
          <b>4. Ofertas e promoções</b>
          <p>A CencoMalls, as Marcas Participantes e/ou Empresas externas poderão oferecer aos Usuários do site ofertas, promoções, concursos por meio de cupons promocionais ou outros meios ad-hoc, para os quais o Usuário deverá aceitar adicionalmente os termos e condições promocionais ou similares do ofertante da respectiva promoção, que será devidamente informado.</p>
          <p>Todos os termos e condições promocionais, de concursos ou similares deverão ser devidamente publicados no site da On Tour e pelo ofertante da Promoção, que é exclusivamente responsável pelo seu cumprimento.</p>
          <b>5. Sanções. Suspensão de operações</b>
          <p>Sem prejuízo de outras medidas, a CencoMalls poderá advertir, suspender temporariamente ou desativar definitivamente um Usuário ou uma publicação, caso o proprietário da publicação incorra em conduta contrária à Lei, à moral, à ordem pública e/ou aos bons costumes.</p>
          <b>6. Propriedade intelectual. Links</b>
          <p>Todos os conteúdos relativos aos serviços On tour, bem como as bases de dados, redes, arquivos, entre outros, que permitem o acesso e o registro do Usuário, são de propriedade do Cencosud Shopping S.A. e estão protegidos pelas leis e tratados internacionais de direito autoral, marcas, patentes, modelos e desenhos industriais. É proibida a utilização indevida e a reprodução total ou parcial dos referidos conteúdos, salvo com a autorização expressa e por escrito do Cencosud Shopping S.A.</p>
          <b>7. Alterações nos termos e condições</b>
          <p>A CencoMalls poderá modificar os Termos e Condições a qualquer momento, publicando os Termos e Condições atualizados na Plataforma.</p>
          <b>8. Central de Ajuda: Contato</b>
          <p>Para apresentar solicitações e/ou reclamações relativas a ações executadas ou concluídas por meio da Plataforma, o Usuário poderá entrar em contato com a ontour@cencomalls.com por e-mail.</p>`,
  },
};

export default tyc;